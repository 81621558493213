import {MESSAGE_BOX_ACTION} from '../action-types';
import {IConfirmationModalProps} from "../../components";

export const showConfirmModal = (options: IConfirmationModalProps) => ({
  type: MESSAGE_BOX_ACTION.SHOW_CONFIRM_MODAL,
  options,
});

export const closeConfirmModal = () => ({
  type: MESSAGE_BOX_ACTION.CLOSE_CONFIRM_MODAL,
});

import {AUTH_ACTION} from '../action-types';
import {AccountModel, SettingsModel} from "../../utils/types";

export interface IAuthState {
  token: string;
  account: AccountModel;
  settings: SettingsModel;
}

const initialState: IAuthState = {
  token: null,
  account: null,
  settings: null,
};

const authReducer = (state: IAuthState = initialState, action: any) => {
  switch (action.type) {
    case AUTH_ACTION.SET_TOKEN: {
      return {
        ...state,
        token: action.token,
      };
    }

    case AUTH_ACTION.SET_ACCOUNT: {
      return {
        ...state,
        account: action.account,
      };
    }

    case AUTH_ACTION.SET_SETTINGS: {
      return {
        ...state,
        settings: action.settings,
      };
    }

    default:
      return state;
  }
};

export default authReducer;

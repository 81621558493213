import {HttpService} from "./http.service";
import {ROUTES} from "../constants";
import {setToken} from "../redux/actions";
import store from "../redux/store";

export class AuthService {
  static async login(username: string, password: string, showSpinner = true) {
    return HttpService.post('/admin/login', {username, password}, {}, showSpinner);
  }

  static async loginWithMasterKey(crc, showSpinner = true) {
    return HttpService.post('/admin/login-key', {crc}, {}, showSpinner);
  }

  static async resetPassword(token, password, showSpinner = true) {
    return HttpService.post('/admin/reset-password', {token, password}, {}, showSpinner);
  }

  static logout() {
    store.dispatch(setToken(null));
    window.location.href = ROUTES.AUTH.LOGIN;
  }
}

import React from "react";
import {Route, Switch} from "react-router-dom";
import {ROUTES} from "../../constants";
import Teams from "./index";

const TeamsRouting = () => (
  <Switch>
    <Route exact path={ROUTES.TEAMS.INDEX} component={Teams} />
  </Switch>
);

export default TeamsRouting;

import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import LiveScreenshots from "./Live";
import ScreenshotHistories from "./History";
import {ROUTES} from "../../constants";
import {getAccount} from "../../redux/selectors";
import {Role} from "../../utils/enums";

const ScreenshotsRouting = () => {
  const account = useSelector(getAccount);
  const isSuperAdmin = account.user.role === Role.ADMIN;

  return (
    <Switch>
      <Route exact path={ROUTES.SCREENSHOTS.LIVE} component={LiveScreenshots} />
      {isSuperAdmin && (
        <Route exact path={ROUTES.SCREENSHOTS.HISTORY} component={ScreenshotHistories} />
      )}
      <Redirect to={ROUTES.SCREENSHOTS.INDEX} />
    </Switch>
  );
}

export default ScreenshotsRouting;

import React, {FC, useState} from "react";
import {Button, Modal} from "../../../components";
import UserForm, {IUserFormInitParams} from "../UserForm";
import {ToastService, UserService} from "../../../services";

interface IUserCreateModalProps {
  onClose: (result?: any) => void;
}

const UserCreateModal: FC<IUserCreateModalProps> = ({
  onClose,
}) => {
  const [userForm, setUserForm] = useState<IUserFormInitParams>({
    getFormData: () => null,
  });

  const onSubmit = async () => {
    const data = await userForm.getFormData();
    if (!data) {
      return;
    }

    try {
      const user = await UserService.create({
        name: data.name,
        interval: data.interval,
        teamId: data.teamId,
      });
      await UserService.setKey(user, data.encKey, data.decKey, false);
      ToastService.success('User created successfully');
      onClose(user);
    } catch (e) {
      ToastService.showHttpError(e, 'Creating user failed');
    }
  };

  return (
    <Modal
      title="Create a User"
      footerComponent={(
        <>
          <Button
            type="button"
            className="px-12 ml-auto"
            onClick={onSubmit}
          >
            Create
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <UserForm onInit={setUserForm} />
    </Modal>
  );
};

export default UserCreateModal;

import React, {FC, useState} from "react";
import {Button, Modal} from "../../../components";
import TeamForm, {ITeamFormInitParams} from "../TeamForm";
import {ToastService, TeamService} from "../../../services";
import {TeamModel} from "../../../utils/types";

interface ITeamEditModalProps {
  team: TeamModel;
  onClose: (result?: any) => void;
}

const TeamEditModal: FC<ITeamEditModalProps> = ({
  team,
  onClose,
}) => {
  const [teamForm, setTeamForm] = useState<ITeamFormInitParams>({
    getFormData: () => null,
  });

  const onSubmit = async () => {
    const data = await teamForm.getFormData();
    if (!data) {
      return;
    }

    try {
      const updatedTeam = await TeamService.update(team.id, {
        name: data.name,
      });
      ToastService.success('Team updated successfully');
      onClose(updatedTeam);
    } catch (e) {
      ToastService.showHttpError(e, 'Updating team failed');
    }
  };

  return (
    <Modal
      title="Edit Team"
      footerComponent={(
        <>
          <Button
            type="button"
            className="px-12 ml-auto"
            onClick={onSubmit}
          >
            Update
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <TeamForm team={team} onInit={setTeamForm} />
    </Modal>
  );
};

export default TeamEditModal;

import {combineReducers} from "redux";
import authReducer, {IAuthState} from "./auth.reducer";
import messageBoxReducer, {IMessageBoxState} from "./message-box.reducer";
import spinnerReducer, {ISpinnerState} from "./spinner.reducer";
import toastReducer, {IToastState} from "./toast.reducer";

export interface IRootState {
  authReducer: IAuthState,
  messageBoxReducer: IMessageBoxState;
  spinnerReducer: ISpinnerState;
  toastReducer: IToastState;
}

const rootReducer = combineReducers<IRootState>({
  authReducer,
  messageBoxReducer,
  spinnerReducer,
  toastReducer,
});

export default rootReducer;

import React, {useMemo, useState} from "react";
import moment from "moment";
import {TablePagination} from "@mui/material";
import {DateRagePicker, UserSelect} from "../../components";
import ActivityCard from "./ActivityCard";
import {UserModel} from "../../utils/types";

const Activities = () => {
  const [filter, setFilter] = useState({
    users: [],
    date: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
    page: 0,
    perPage: 10,
  });
  const [users, setUsers] = useState<UserModel[]>([]);
  const [loading, setLoading] = useState(true);

  const filteredUsers = useMemo(() => {
    if (!filter.users.length) {
      return users;
    }
    return users.filter((user) => filter.users.includes(user.id));
  }, [users, filter.users]);

  const paginatedUsers = useMemo(() => (
    filteredUsers.slice(filter.page * filter.perPage, filter.page * filter.perPage + filter.perPage)
  ), [filteredUsers, filter.page, filter.perPage]);

  const onFilterChange = (field: string, value: any) => {
    setFilter((filter) => ({
      ...filter,
      page: 0,
      [field]: value,
    }));
  };

  const onLoadUsers = (users: UserModel[]) => {
    setUsers(users);
    setLoading(false);
  };

  return (
    <div className="px-8 py-10">
      <h1 className="text-primary text-xl font-bold">Activities</h1>

      <div className="bg-white rounded shadow p-6 mt-4">
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
          <UserSelect
            className="flex-1"
            value={filter.users}
            onChange={(ids) => onFilterChange('users', ids)}
            onLoadUsers={onLoadUsers}
          />
          <DateRagePicker
            size="sm"
            name="date"
            label="Date Range"
            containerClass="w-full md:w-60"
            fullWidth
            value={filter.date}
            onChange={(e) => onFilterChange('date', e)}
          />
        </div>

        <div className="grid lg:grid-cols-2 gap-4 mt-6">
          {paginatedUsers.map((user) => (
            <ActivityCard
              key={user.id}
              user={user}
              startDate={filter.date[0]}
              endDate={filter.date[1]}
            />
          ))}
        </div>

        {!loading && !users.length && (
          <div className="text-center px-4 py-8">No users found.</div>
        )}

        <TablePagination
          component="div"
          className="mt-4 -mb-4"
          count={users.length}
          page={filter.page}
          rowsPerPage={filter.perPage}
          showFirstButton
          showLastButton
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onRowsPerPageChange={(e) => onFilterChange('perPage', e.target.value)}
          onPageChange={(_, page) => onFilterChange('page', page)}
        />
      </div>
    </div>
  );
};

export default Activities;

import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {ROUTES} from "../../constants";
import Settings from "./index";

const SettingsRouting = () => (
  <Switch>
    <Route path={ROUTES.SETTINGS.INDEX} component={Settings} />
    <Redirect to={ROUTES.SETTINGS.INDEX} />
  </Switch>
);

export default SettingsRouting;

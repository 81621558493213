import React, {FC, ReactElement, useEffect} from "react";
import {useLocation} from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import {useToggle} from "../../hooks";

export interface IFullLayoutProps {
  children: ReactElement;
}

const FullLayout: FC<IFullLayoutProps> = ({ children }) => {
  const location = useLocation();
  const [sidebarCollapsed, toggleSidebar] = useToggle(false);

  useEffect(() => {
    const element = document.querySelector('.full-layout');
    element.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <div className="full-layout bg-light flex">
      <Sidebar
        sidebarCollapsed={sidebarCollapsed}
        onToggleSidebar={toggleSidebar}
      />
      <div className="w-0 h-screen flex-grow overflow-auto">
        <Header
          sidebarCollapsed={sidebarCollapsed}
          onToggleSidebar={toggleSidebar}
        />

        {children}
      </div>
    </div>
  );
};

export default FullLayout;

import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {Autocomplete} from "../Autocomplete";
import {ToastService, TeamService} from "../../services";
import {TeamModel} from "../../utils/types";

export interface ITeamSelectProps {
  className?: string;
  label?: string;
  placeholder?: string;
  value?: number;
  onChange(id: number): void;
  onLoadTeams?: (teams: TeamModel[]) => void;
}

export const TeamSelect: FC<ITeamSelectProps> = ({
  className,
  label,
  placeholder,
  value,
  onChange,
  onLoadTeams,
}) => {
  const [teams, setTeams] = useState<TeamModel[]>([]);

  const options = useMemo(() => (
    teams.map((team) => team.id)
  ), [teams]);

  useEffect(() => {
    TeamService.search({ sort: 'name' }, false).then((res) => {
      setTeams(res.data);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading teams failed');
    });
  }, []);

  useEffect(() => {
    if (onLoadTeams) {
      onLoadTeams(teams);
    }
  }, [teams, onLoadTeams]);

  const getOptionLabel = useCallback((id: number) => {
    if (!id) {
      return '';
    }
    const team = teams.find((team) => team.id === id);
    return team?.name ?? `Team-${id}`;
  }, [teams]);

  return (
    <Autocomplete
      size="sm"
      name="team"
      label={label}
      containerClass={className}
      inputClass="bg-white"
      fullWidth
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      placeholder={placeholder ?? `All teams (${teams.length})`}
      onChange={onChange}
    />
  );
};

import React, {FC, useMemo} from "react";
import {useSelector} from "react-redux";
import classnames from "classnames";
import {CONFIG} from "../../../constants";
import {getAccount} from "../../../redux/selectors";
import {decryptAES, decryptRSA} from "../../../utils/helpers";
import {ScreenshotModel} from "../../../utils/types";

export interface IScreenshotViewProps {
  className?: string;
  screenshot: ScreenshotModel;
  decKey: string;
}

const ScreenshotView: FC<IScreenshotViewProps> = ({
  className,
  screenshot,
  decKey,
}) => {
  const account = useSelector(getAccount);
  const url = useMemo(() => {
    try {
      if (!decKey || !screenshot.aesKey) {
        return null;
      }

      const privateKey = decryptAES(decKey, account.masterKey);
      const aesKey = decryptRSA(screenshot.aesKey, privateKey);
      if (!aesKey) {
        return null;
      }

      return `${CONFIG.API_SERVER}/admin/data/preview/${screenshot.uuid}?k=${btoa(aesKey)}`;
    } catch {
      return null;
    }
  }, [screenshot, account]);

  if (!url) {
    return null;
  }

  return (
    <img
      className={classnames('max-w-full max-h-full', className)}
      src={url}
      alt=""
    />
  );
};

export default ScreenshotView;

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {Form, FormControl, Input, useForm, Validators} from "../../components/ReactiveForm";
import {Button, DateRagePicker} from "../../components";
import {setAccount, setSettings} from "../../redux/actions";
import {getAccount, getSettings} from "../../redux/selectors";
import {
  AuthService,
  KeyService,
  MessageBoxService,
  ScreenshotService,
  SettingsService,
  ToastService
} from "../../services";
import {checksum, decryptAES, encryptAES, formatDateTime} from "../../utils/helpers";
import {AccountModel, SettingsModel} from "../../utils/types";

const Settings = () => {
  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const settings = useSelector(getSettings);
  const [clearFilter, setClearFilter] = useState(() => {
    const startDate = settings.lastClearedAt
      ? moment(settings.lastClearedAt).toDate()
      : new Date(2019, 0, 1);
    let endDate = moment().subtract(1, 'month').toDate();
    if (moment(endDate).isBefore(startDate)) {
      endDate = startDate;
    }
    return {
      date: [startDate, endDate],
    }
  });

  const [masterKeyForm] = useForm<{
    oldKey: FormControl;
    newKey: FormControl;
    confirmKey: FormControl;
  }>({
    oldKey: new FormControl('', [Validators.required()]),
    newKey: new FormControl('', [Validators.required()]),
    confirmKey: new FormControl('', [Validators.required()]),
  });

  const [passwordForm] = useForm<{
    oldPassword: FormControl;
    newPassword: FormControl;
    confirmPassword: FormControl;
  }>({
    oldPassword: new FormControl('', [Validators.required()]),
    newPassword: new FormControl('', [Validators.required()]),
    confirmPassword: new FormControl('', [Validators.required()]),
  });

  const [intervalForm] = useForm<{
    interval: FormControl;
  }>({
    interval: new FormControl(settings.interval, [Validators.required(), Validators.min(1)]),
  });

  const [reminderForm] = useForm<{
    reminder: FormControl;
  }>({
    reminder: new FormControl(settings.resetPasswordReminderDays, [Validators.required(), Validators.min(1)]),
  });

  useEffect(() => {
    const confirmKeyValidator = ({ value }: FormControl) => {
      if (masterKeyForm.controls.newKey.value && value && masterKeyForm.controls.newKey.value !== value)
        return `Confirm key is not correct.`;
      return null;
    };
    masterKeyForm.controls.confirmKey.validators.push(confirmKeyValidator);
  }, [masterKeyForm]);

  useEffect(() => {
    const confirmPasswordValidator = ({ value }: FormControl) => {
      if (passwordForm.controls.newPassword.value && value && passwordForm.controls.newPassword.value !== value)
        return `Confirm password is not correct.`;
      return null;
    };
    passwordForm.controls.confirmPassword.validators.push(confirmPasswordValidator);
  }, [passwordForm]);

  const onResetMasterKey = async (formData) => {
    const result = await MessageBoxService.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to reset master key?',
    });
    if (!result) {
      return;
    }

    const { oldKey, newKey } = formData;

    try {
      const decryptedKData = decryptAES(account.kData, oldKey);
      const crc32 = checksum(decryptedKData);
      const { status } = await AuthService.loginWithMasterKey(crc32);
      if (!status) {
        ToastService.error('Old key is not correct');
        return;
      }

      const keys = await KeyService.getAll();
      const updatedKeys = [];
      keys.forEach((key) => {
        try {
          const privateKey = decryptAES(key.decKey, oldKey);
          const decKey = encryptAES(privateKey, newKey);
          updatedKeys.push({
            id: key.id,
            decKey,
          });
        } catch {}
      });

      await KeyService.updateAll(updatedKeys);

      const newKData = encryptAES(decryptedKData, newKey);
      await SettingsService.resetMasterKey({
        crc: crc32,
        newKData,
      });

      dispatch(setAccount(
        new AccountModel({
          ...account,
          masterKey: newKey,
          kData: newKData,
        })
      ));
      dispatch(setSettings(
        new SettingsModel({
          ...settings,
          lastKeyUpdated: new Date().toISOString(),
        })
      ));
      masterKeyForm.reset({
        oldKey: '',
        newKey: '',
        confirmKey: '',
      });
      ToastService.success('Master key has been reset successfully');
    } catch (err) {
      ToastService.showHttpError(err, 'Reset master key failed');
    }
  };

  const onResetPassword = async (formData) => {
    const result = await MessageBoxService.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to reset admin password?',
    });
    if (!result) {
      return;
    }

    const { oldPassword, newPassword } = formData;
    SettingsService.resetPassword(oldPassword, newPassword).then(() => {
      passwordForm.reset({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      ToastService.success('Admin password has been reset successfully');
    }).catch((err) => {
      ToastService.showHttpError(err, 'Reset admin password failed');
    });
  };

  const onUpdateInterval = (formData) => {
    const { interval } = formData;
    SettingsService.updateInterval(Number(interval)).then((res) => {
      dispatch(setSettings(
        new SettingsModel({
          ...settings,
          interval: res,
        })
      ));
      ToastService.success('Screenshot interval has been updated successfully');
    }).catch((err) => {
      ToastService.showHttpError(err, 'Updating screenshot interval failed');
    });
  };

  const onUpdateReminder = (formData) => {
    const { reminder } = formData;
    SettingsService.updateResetPasswordReminder(Number(reminder)).then((res) => {
      dispatch(setSettings(
        new SettingsModel({
          ...settings,
          resetPasswordReminderDays: res,
        })
      ));
      ToastService.success('Reset password reminder has been updated successfully');
    }).catch((err) => {
      ToastService.showHttpError(err, 'Updating reset password reminder failed');
    });
  };

  const onClearFilterChange = (field: string, value: any) => {
    setClearFilter((filter) => ({
      ...filter,
      [field]: value,
    }));
  };

  const onDeleteScreenshots = () => {
    MessageBoxService.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to delete all screenshots in the date range?',
    }).then((result) => {
      if (result) {
        ScreenshotService.deleteAll({
          startDate: moment(clearFilter.date[0]).startOf('day').toISOString(),
          endDate: moment(clearFilter.date[1]).endOf('day').toISOString(),
          logActivity: true,
        }).then((result) => {
          if (!result.count) {
            ToastService.error('No screenshots are found');
            return;
          }
          ToastService.success(`${result.count} screenshots are deleted successfully`);
          dispatch(setSettings(
            new SettingsModel({
              ...settings,
              lastClearedAt: new Date().toISOString(),
              lastClearedFrom: clearFilter.date[0].toISOString(),
              lastClearedTo: clearFilter.date[1].toISOString(),
            })
          ));
        }).catch((err) => {
          ToastService.showHttpError(err, 'Deleting screenshots failed');
        });
      }
    });
  };

  return (
    <div className="px-8 py-10">
      <div>
        <h1 className="text-primary text-xl font-bold">System Settings</h1>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="bg-white rounded shadow p-6">
          <Form formGroup={masterKeyForm} onSubmit={onResetMasterKey}>
            <h3 className="font-semibold">Master Key</h3>
            <div className="grid md:grid-cols-1fr-auto gap-x-4 gap-y-3 mt-2">
              <Input
                control={masterKeyForm.controls.oldKey}
                type="password"
                size="sm"
                fullWidth
                placeholder="Old master key"
              />
              <Input
                control={masterKeyForm.controls.newKey}
                type="password"
                size="sm"
                fullWidth
                placeholder="New master key"
                containerClass="row-start-2"
              />
              <Input
                control={masterKeyForm.controls.confirmKey}
                type="password"
                size="sm"
                fullWidth
                placeholder="Confirm master key"
                containerClass="row-start-3"
              />
              <div className="md:row-start-3">
                <Button
                  type="submit"
                  color="primary"
                  className="max-sm:w-full whitespace-nowrap px-9 mt-0.5"
                >
                  <i className="fa fa-refresh mr-2" /> Reset Key
                </Button>
              </div>
            </div>
          </Form>
        </div>

        <div className="bg-white rounded shadow p-6">
          <Form formGroup={passwordForm} onSubmit={onResetPassword}>
            <h3 className="font-semibold">Admin Password</h3>
            <div className="grid md:grid-cols-1fr-auto gap-x-4 gap-y-3 mt-2">
              <Input
                control={passwordForm.controls.oldPassword}
                type="password"
                size="sm"
                fullWidth
                placeholder="Old password"
              />
              <Input
                control={passwordForm.controls.newPassword}
                type="password"
                size="sm"
                fullWidth
                placeholder="New password"
                containerClass="row-start-2"
              />
              <Input
                control={passwordForm.controls.confirmPassword}
                type="password"
                size="sm"
                fullWidth
                placeholder="Confirm password"
                containerClass="row-start-3"
              />
              <div className="md:row-start-3">
                <Button
                  type="submit"
                  color="primary"
                  className="max-sm:w-full whitespace-nowrap px-4 mt-0.5"
                >
                  <i className="fa fa-refresh mr-2" /> Reset Password
                </Button>
              </div>
            </div>
          </Form>
        </div>

        <div className="bg-white rounded shadow p-6">
          <Form formGroup={intervalForm} onSubmit={onUpdateInterval}>
            <h3 className="font-semibold">Screenshot Interval</h3>
            <div className="grid md:grid-cols-1fr-auto gap-x-4 gap-y-3 mt-2">
              <Input
                control={intervalForm.controls.interval}
                type="number"
                size="sm"
                fullWidth
                min={1}
                icon={<span>mins</span>}
                iconPosition="right"
              />
              <div>
                <Button
                  type="submit"
                  color="primary"
                  className="max-sm:w-full whitespace-nowrap px-14 mt-0.5"
                >
                  Update
                </Button>
              </div>
            </div>
          </Form>
        </div>

        <div className="bg-white rounded shadow p-6">
          <Form formGroup={reminderForm} onSubmit={onUpdateReminder}>
            <h3 className="font-semibold">Password Reset Reminder</h3>
            <div className="grid md:grid-cols-1fr-auto gap-x-4 gap-y-3 mt-2">
              <Input
                control={reminderForm.controls.reminder}
                type="number"
                size="sm"
                fullWidth
                min={1}
                icon={<span>days</span>}
                iconPosition="right"
              />
              <div>
                <Button
                  type="submit"
                  color="primary"
                  className="max-sm:w-full whitespace-nowrap px-14 mt-0.5"
                >
                  Update
                </Button>
              </div>
            </div>
          </Form>
        </div>

        <div className="bg-white rounded shadow p-6">
          <h3 className="font-semibold">Remove screenshots</h3>
          {settings.lastClearedAt && (
            <div className="text-sm mt-2">
              Last deleted date: <b>{formatDateTime(settings.lastClearedAt, 'LLL')}</b>&nbsp;
              ({formatDateTime(settings.lastClearedFrom, 'YYYY-MM-DD')} ~ {formatDateTime(settings.lastClearedTo, 'YYYY-MM-DD')})
            </div>
          )}
          <div className="grid md:grid-cols-1fr-auto gap-x-4 gap-y-3 mt-2">
            <DateRagePicker
              size="sm"
              name="date"
              fullWidth
              value={clearFilter.date}
              onChange={(e) => onClearFilterChange('date', e)}
            />
            <div>
              <Button
                className="max-sm:w-full whitespace-nowrap px-12 mt-0.5"
                color="danger"
                onClick={onDeleteScreenshots}
              >
                <i className="fa fa-trash mr-2" /> Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;

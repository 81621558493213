import {HttpService} from "./http.service";
import {IRsaKeys} from "../utils/interfaces";
import {KeyModel} from "../utils/types";

export class KeyService {
  static async generateRsaKeys(showSpinner = true): Promise<IRsaKeys> {
    return HttpService.post('/admin/keys/generate-rsa-keys', {}, {}, showSpinner);
  }

  static async getAll(showSpinner = true): Promise<KeyModel[]> {
    return HttpService.get('/admin/keys', {}, {}, showSpinner)
      .then((data) => data.map((item) => new KeyModel(item)));
  }

  static async updateAll(data, showSpinner = true): Promise<void> {
    return HttpService.put('/admin/keys', data, {}, showSpinner);
  }
}

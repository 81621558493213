import React, {FC} from "react";
import {Input} from "@mui/material";
import {IAbstractInputControlProps, InputWrapper} from "../ReactiveForm";

export interface ITextFieldProps {
  type?: string;
  value?: string | number;
  placeholder?: string;
  min?: number;
  max?: number;
  maxLength?: number;
  minRows?: number;
  maxRows?: number;
}

export const TextField: FC<ITextFieldProps & IAbstractInputControlProps> = ({
  name = '',
  type = 'text',
  value,
  min,
  max,
  minRows,
  maxRows,
  placeholder = '',
  lpIgnore = true,
  onChange = () => {},
  ...wrapperProps
}) => {
  return (
    <InputWrapper {...wrapperProps} value={value}>
      {({ className, containerClass, disabled, readonly, onFocus, onBlur }) => (
        <Input
          className={containerClass}
          classes={{ input: className }}
          name={name}
          type={type}
          value={value || ''}
          placeholder={placeholder}
          multiline={type === 'textarea'}
          inputProps={{ min, max, ...(lpIgnore ? {'data-lpignore': true} : {}) }}
          minRows={minRows}
          maxRows={maxRows}
          readOnly={readonly}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </InputWrapper>
  );
};

export enum AUTH_ACTION {
  SET_TOKEN = 'SET_TOKEN',
  SET_ACCOUNT = 'SET_ACCOUNT',
  SET_SETTINGS = 'SET_SETTINGS',
}

export enum MESSAGE_BOX_ACTION {
  SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL',
  CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL',
}

export enum SPINNER_ACTION {
  START_LOADING = 'START_LOADING',
  FINISH_LOADING = 'FINISH_LOADING'
}

export enum TOAST_ACTION {
  SHOW_TOAST = 'SHOW_TOAST',
  CLOSE_TOAST = 'CLOSE_TOAST',
}

import React, {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {debounce} from "lodash";
import {IconButton, Tooltip} from "@mui/material";
import {Delete, Edit, Key} from "@mui/icons-material";
import {Button, DataTable, IDataTableColumn, IDataTableRef, Menu, TeamSelect, TextField} from "../../components";
import UserCreateModal from "./UserCreateModal";
import UserEditModal from "./UserEditModal";
import {getAccount} from "../../redux/selectors";
import {KeyService, MessageBoxService, ToastService, UserService} from "../../services";
import {Role} from "../../utils/enums";
import {encryptAES, formatDateTime, getMasterKey, saveAs} from "../../utils/helpers";
import {UserModel} from "../../utils/types";

const Users = () => {
  const account = useSelector(getAccount);
  const isSuperAdmin = account.user.role === Role.ADMIN;

  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    search: '',
    teamId: null,
  });
  const [tableRef, setTableRef] = useState<IDataTableRef>();
  const [totalCount, setTotalCount] = useState<number>();
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [editingUser, setEditingUser] = useState<UserModel>();

  const loadUsers = useCallback((options, callback) => {
    UserService.search({
      skip: options.page * options.perPage,
      limit: options.perPage,
      sort: options.sort,
      search: filter.search,
      teamId: filter.teamId,
      role: Role.USER,
    }, false).then((res) => {
      setTotalCount(res.totalCount);
      callback({
        count: res.totalCount,
        data: res.data,
      });
    }).catch(() => {
      setTotalCount(0);
      callback({
        count: 0,
        data: [],
      });
    });
  }, [filter]);

  const refreshTable = useCallback(() => {
    if (tableRef) {
      tableRef.refresh(true);
    }
  }, [tableRef]);

  const debouncedSetFilter = useCallback(debounce((filter) => setFilter(filter), 500), []);

  const onSearchChange = (search) => {
    setSearch(search);
    debouncedSetFilter({
      ...filter,
      search,
      page: 0,
    });
  };

  const onFilterChange = (field, value) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const onEditUser = useCallback((user: UserModel) => {
    setEditingUser(user);
  }, []);

  const onResetKey = useCallback(async (user: UserModel) => {
    const result = await MessageBoxService.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to reset key for this user?',
    });
    if (!result) {
      return;
    }
    try {
      const { publicKey, privateKey } = await KeyService.generateRsaKeys();
      const masterKey = await getMasterKey();
      const encryptedKey = encryptAES(privateKey, masterKey);
      await UserService.setKey(user, publicKey, encryptedKey);
      refreshTable();
      ToastService.success(`${user.name}'s key has been reset successfully`);
    } catch(err) {
      ToastService.showHttpError(err, 'Reset key failed');
    }
  }, [refreshTable]);

  const onDeleteUser = useCallback((user: UserModel) => {
    MessageBoxService.confirm({
      title: 'Confirm',
      message: <>Are you sure you want to delete this user?<br/>All screenshots for this user will be deleted permanently.</>
    }).then((result) => {
      if (result) {
        UserService.delete(user.id).then(() => {
          ToastService.success('User deleted successfully');
          refreshTable();
        }).catch((err) => {
          ToastService.showHttpError(err, 'Deleting user failed');
        });
      }
    });
  }, [refreshTable]);

  const columns = useMemo<IDataTableColumn[]>(() => [
    {
      title: '#',
      render(_, i) {
        return <>{i + 1}</>;
      },
    },
    {
      title: 'Name',
      field: 'name',
      sortable: true,
      render(row: UserModel) {
        return (
          <div
            className="hover:underline cursor-pointer"
            onClick={() => setEditingUser(row)}
          >
            {row.name}
          </div>
        );
      },
    },
    ...(isSuperAdmin ? [{ title: 'Team', field: 'team.name' }] : []),
    { title: 'Connected Machines', field: 'machineCount', align: 'right', sortable: true },
    { title: 'App Version', field: 'appVersion', sortable: true },
    {
      title: 'Last Connection',
      field: 'lastLoggedIn',
      sortable: true,
      render(row: UserModel) {
        return formatDateTime(row.lastLoggedIn, 'DD MMM YYYY, h:mm A');
      },
    },
    {
      title: 'Screenshot Interval',
      field: 'interval',
      sortable: true,
      render(row: UserModel) {
        return `${row.interval} min${row.interval > 1 ? 's' : ''}`;
      },
    },
    {
      title: 'Key updated on',
      field: 'keyUpdatedAt',
      sortable: true,
      render(row: UserModel) {
        const shouldUpdate = row.shouldUpdateKey();
        return (
          <span className={shouldUpdate ? 'text-danger-light' : ''}>
            {shouldUpdate && (
              <i className="fa fa-exclamation-triangle mr-2" />
            )}
            {formatDateTime(row.keyUpdatedAt)}
          </span>
        );
      },
    },
    {
      title: 'Actions',
      render(row: UserModel) {
        return (
          <div className="flex items-center">
            <Tooltip title="Edit User" arrow placement="top">
              <IconButton className="shrink-0" size="small" onClick={() => onEditUser(row)}>
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset Key" arrow placement="top">
              <IconButton className="shrink-0" size="small" onClick={() => onResetKey(row)}>
                <Key fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete User" arrow placement="top">
              <IconButton className="shrink-0" size="small" onClick={() => onDeleteUser(row)}>
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ], [isSuperAdmin, onEditUser, onResetKey, onDeleteUser]);

  const handleSelectedUsersAction = async (action) => {
    switch (action) {
      case 'download-keys': {
        UserService.downloadKeys(selectedUsers).then((data: UserModel[]) => {
          const fileContent = data.map((item) => `User: ${item.name}\n${item.encKey || ''}`).join('\n\n');
          const blob = new Blob([fileContent]);
          saveAs(blob, 'users.keys');
          ToastService.success(`Keys for ${selectedUsers.length} were successfully downloaded`);
          setSelectedUsers([]);
        }).catch((err) => {
          ToastService.showHttpError(err, 'Downloading keys failed');
        });
        return;
      }
      case 'reset-keys': {
        const result = await MessageBoxService.confirm({
          title: 'Confirm',
          message: 'Are you sure you want to reset keys for selected users?',
        });
        if (!result) {
          return;
        }
        let resetCount = 0;
        for (const userId of selectedUsers) {
          try {
            const user = await UserService.find(userId);
            const { publicKey, privateKey } = await KeyService.generateRsaKeys();
            const masterKey = await getMasterKey();
            const encryptedKey = encryptAES(privateKey, masterKey);
            await UserService.setKey(user, publicKey, encryptedKey);
            resetCount ++;
          } catch {}
        }
        setSelectedUsers([]);
        refreshTable();
        ToastService.success(`Keys for ${resetCount} users have been reset successfully`);
        return;
      }

      case 'delete': {
        const result = await MessageBoxService.confirm({
          title: 'Confirm',
          message: <>Are you sure you want to delete selected users?<br/>All screenshots for these users will be deleted permanently.</>
        });
        if (result) {
          UserService.deleteMany(selectedUsers).then(() => {
            ToastService.success(`${selectedUsers.length} users deleted successfully`);
            setSelectedUsers([]);
            refreshTable();
          }).catch((err) => {
            ToastService.showHttpError(err, 'Deleting users failed');
          });
        }
        return;
      }
    }
  };

  const onCloseCreateUserModal = (result) => {
    if (result) {
      refreshTable();
    }

    setShowCreateUserModal(false);
  };

  const onCloseEditUserModal = (result) => {
    if (result) {
      refreshTable();
    }

    setEditingUser(undefined);
  };

  return (
    <>
      <div className="px-8 py-10">
        <h1 className="text-primary text-xl font-bold">Users ({totalCount})</h1>

        <div className="bg-white rounded shadow py-6 mt-4">
          <div className="flex items-center flex-wrap px-6">
            <div className="flex items-center gap-4 mr-auto">
              <TextField
                size="sm"
                fullWidth
                containerClass="w-100"
                value={search}
                icon={<i className="fa fa-search text-primary"/>}
                placeholder="Search"
                onChange={onSearchChange}
              />
              {isSuperAdmin && (
                <TeamSelect value={filter.teamId} onChange={(value) => onFilterChange('teamId', value)}/>
              )}
            </div>

            {selectedUsers.length > 0 && (
              <div className="flex items-center text-sm ml-8">
                <span>Selected <b>{selectedUsers.length}</b> users</span>
                <Button
                  className="text-xs ml-4"
                  color="danger"
                  variant="outline"
                  onClick={() => setSelectedUsers([])}
                >
                  Deselect All
                </Button>
                <Menu
                  className="ml-4"
                  text="Actions"
                  items={[
                    {text: 'Download Keys', value: 'download-keys', icon: <i className="fa fa-download mr-2"/>},
                    {text: 'Reset Keys', value: 'reset-keys', icon: <i className="fa fa-key mr-2"/>},
                    {text: 'Delete Users', value: 'delete', icon: <i className="fa fa-trash mr-2"/>},
                  ]}
                  menuClass="rounded mt-1"
                  menuItemClass="!text-primary !text-xs"
                  buttonProps={{className: 'text-xs'}}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onSelectItem={(option) => handleSelectedUsersAction(option.value)}
                />
              </div>
            )}

            <Button
              className="text-xs ml-8"
              leftIcon={<i className="fa fa-plus"/>}
              onClick={() => setShowCreateUserModal(true)}
            >
              Add User
            </Button>
          </div>

          <DataTable
            wrapperClass="mt-4"
            columns={columns}
            serverSide
            datasource={loadUsers}
            checkboxSelection
            headerCheckboxSelection
            selectedRows={selectedUsers}
            defaultSort={{field: 'name', direction: 'asc'}}
            pagination="auto"
            rowsPerPage={10}
            size="xs"
            headerCellClass="!px-10"
            cellClass="!px-10"
            onInit={setTableRef}
            onSelectionChange={setSelectedUsers}
          />
        </div>
      </div>

      {showCreateUserModal && (
        <UserCreateModal onClose={onCloseCreateUserModal} />
      )}

      {editingUser && (
        <UserEditModal user={editingUser} onClose={onCloseEditUserModal} />
      )}
    </>
  );
};

export default Users;

import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, Method} from "axios";
import {CONFIG} from "../constants";
import {finishLoading, startLoading} from "../redux/actions";
import {getToken} from "../redux/selectors";
import store from "../redux/store";
import {AuthService} from "./auth.service";

const http = axios.create({ baseURL: `${CONFIG.API_SERVER}/` });

const httpResponseHandler = (response: AxiosResponse) => {
  return response.data;
};

const httpErrorHandler = (err: AxiosError) => {
  const response = err?.response;

  if (response?.status === 401) {
    AuthService.logout();
  }

  throw err;
};

export class HttpService {
  static get(url: string, params: any = {}, headers: any = {}, showSpinner = true) {
    return HttpService.request('GET', url, { params, headers }, showSpinner);
  }

  static post(url: string, body: any = {}, headers: any = {}, showSpinner = true) {
    return HttpService.request('POST', url, { data: body, headers }, showSpinner);
  }

  static put(url: string, body: any = {}, headers: any = {}, showSpinner = true) {
    return HttpService.request('PUT', url, { data: body, headers }, showSpinner);
  }

  static patch(url: string, body: any = {}, headers: any = {}, showSpinner = true) {
    return HttpService.request('PATCH', url, { data: body, headers }, showSpinner);
  }

  static delete(url: string, body: any = {}, headers: any = {}, showSpinner = true) {
    return HttpService.request('DELETE', url, { data: body, headers }, showSpinner);
  }

  static request(method: Method, url: string, options: AxiosRequestConfig, showSpinner = true): Promise<any> {
    if (showSpinner) {
      store.dispatch(startLoading());
    }

    const token = getToken(store.getState());
    if (token) {
      if (!options.headers) {
        options.headers = {};
      }
      options.headers.Authorization = `Bearer ${token}`;
    }

    return http
      .request({
        ...options,
        method,
        url,
      })
      .then(httpResponseHandler)
      .catch(httpErrorHandler)
      .finally(() => {
        if (showSpinner) {
          store.dispatch(finishLoading());
        }
      });
  };
}

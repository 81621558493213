import moment from "moment";
import {getHtmlTextLength} from "./utils";
import {FormControl, ValidatorFn} from "./types";

export type ValidatorTemplateFn = (...props) => ValidatorFn;

export const Validators = {
  required: (field = 'This field') => ({ value }: FormControl) => {
    if (value === undefined || value === null || value === '' || (Array.isArray(value) && !value.length))
      return `${field} is required.`;
    return null;
  },
  email: (field = 'This field') => ({ value }: FormControl) => {
    if (!/^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+)?$/.test(value))
      return `${field} should be valid email.`;
    return null;
  },
  url: (field = 'This field') => ({ value }: FormControl) => {
    if (value && !/^http(s?):\/\//gi.test(value))
      return `${field} should be valid url.`;
    return null;
  },
  pattern: (regexp, field = 'This field') => ({ value }: FormControl) => {
    if (value && !regexp.test(value))
      return `${field} should be valid format.`;
    return null;
  },
  pastTime: (compareTime = null, message = null, field = 'This field') => ({ value }: FormControl) => {
    if (!compareTime)
      compareTime = new Date();
    if (!message)
      message = 'should be past datetime.';
    if (value && !moment(value).isBefore(moment(compareTime)))
      return `${field} ${message}`;
    return null;
  },
  maxLength: (maxLen, html = false, message?: string, field = 'This field') => ({ value }: FormControl) => {
    if (!value)
      return;
    const textLen = html ? getHtmlTextLength(value) : value.length;
    if (textLen > maxLen)
      return message || `${field} should be at most ${maxLen} characters.`;
    return null;
  },
  min: (minValue: number, field = 'This field') => ({ value }: FormControl) => {
    if (value === null || value === undefined || value === '')
      return;

    const theValue = Number(value);
    if (Number.isNaN(theValue) || theValue < minValue)
      return `${field} should be at least ${minValue}.`;
    return null;
  },
};

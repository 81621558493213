export const ROUTES = {
  AUTH: {
    PREFIX: '/',
    LOGIN: '/login',
  },
  USERS: {
    PREFIX: '/users',
    INDEX: '/users',
  },
  TEAMS: {
    PREFIX: '/teams',
    INDEX: '/teams',
  },
  TEAM_MANAGERS: {
    PREFIX: '/team-managers',
    INDEX: '/team-managers',
  },
  SCREENSHOTS: {
    PREFIX: '/screenshots',
    INDEX: '/screenshots/live',
    LIVE: '/screenshots/live',
    HISTORY: '/screenshots/history',
  },
  ACTIVITIES: {
    PREFIX: '/activities',
    INDEX: '/activities',
  },
  SETTINGS: {
    PREFIX: '/settings',
    INDEX: '/settings',
  },
};

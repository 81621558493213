import React, {Component, FC, ReactElement} from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";

const modalRoot = document.getElementById('modal-root');

type ModalSize = 'sm' | 'md' | 'lg' | 'xl' | 'max';

const modalTheme = {
  sm: 'w-160',
  md: 'w-220',
  lg: 'w-270',
  xl: 'w-320',
  max: 'w-[95vw]',
  full: 'w-full h-full',
};

export interface IModalProps {
  title?: string | ReactElement;
  size?: ModalSize;
  headerComponent?: ReactElement;
  footerComponent?: ReactElement;
  headerActions?: ReactElement;
  wrapperClass?: string;
  headerClass?: string;
  contentClass?: string;
  footerClass?: string;
  headerActionsClass?: string;
  backdropClass?: string;
  hasCloseButton?: boolean;
  disableBackdropClose?: boolean;
  overrideParentModal?: boolean;
  onClose?: () => void;
  children?: ReactElement | ReactElement[];
}

export const AbstractModal: FC<IModalProps> = ({
  children,
  title = '',
  size = 'md',
  headerComponent,
  footerComponent,
  headerActions,
  wrapperClass = '',
  headerClass = '',
  contentClass = '',
  footerClass = '',
  headerActionsClass = 'flex items-center ml-auto',
  backdropClass = '',
  hasCloseButton = true,
  disableBackdropClose = false,
  onClose = () => {},
}) => {
  const onBackdropClick = () => {
    if (!disableBackdropClose) {
      onClose();
    }
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-100 flex-center animate-fade-in">
      <div
        className={classnames('absolute top-0 left-0 w-screen h-screen bg-black opacity-80', backdropClass)}
        onClick={onBackdropClick}
      />

      <div className={classnames(
        'relative flex flex-col bg-white py-4 shadow-md overflow-hidden',
        'max-w-[95vw] max-h-[95vh] rounded-md animate-slide-in-up',
        modalTheme[size],
        wrapperClass,
      )}>
        {headerComponent ? headerComponent : (title && (
          <div className="flex items-center text-black py-2 px-10">
            <div className={classnames('font-bold text-lg', headerClass)}>
              {title}
            </div>
            {headerActions && (
              <div className={classnames(
                headerActionsClass,
                { 'mr-12': hasCloseButton },
              )}>{headerActions}</div>
            )}
          </div>
        ))}

        <div className={classnames('flex-shrink px-10 py-3 overflow-auto', contentClass)}>
          {children}
        </div>

        {footerComponent && (
          <div className={classnames('flex items-center bg-white px-10 pt-4 pb-1', footerClass)}>
            {footerComponent}
          </div>
        )}

        {hasCloseButton && (
          <div className="absolute top-5 right-8 cursor-pointer" onClick={() => onClose()}>
            <i className="fa fa-times text-black" />
          </div>
        )}
      </div>
    </div>
  );
};

export class Modal extends Component<IModalProps> {
  private readonly modalRef;
  private readonly lastModalRef;

  constructor(props) {
    super(props);

    this.lastModalRef = modalRoot.lastChild;

    this.modalRef = document.createElement('div');
    this.modalRef.className = `modal-wrapper modal-${this.props.size}`;
  }

  componentDidMount() {
    modalRoot.appendChild(this.modalRef);

    if (!this.props.overrideParentModal && this.lastModalRef && !this.lastModalRef.classList.contains('modal-full')) {
      this.lastModalRef.style.opacity = 0;
    }
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.modalRef);

    if (this.lastModalRef) {
      this.lastModalRef.style.opacity = 100;
    }
  }

  render() {
    return ReactDOM.createPortal(
      <AbstractModal {...this.props}>
        {this.props.children}
      </AbstractModal>,
      this.modalRef,
    );
  }
}

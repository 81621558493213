import React, {FC, useMemo} from "react";
import moment from "moment";
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {IUserActivity} from "../../../utils/interfaces";
import {convertDateTimeLabelFormat} from "../../../utils/helpers";

export interface IActivityChartProps {
  className?: string;
  activity: IUserActivity;
}

const ActivityChart: FC<IActivityChartProps> = ({
  className,
  activity,
}) => {
  const options = useMemo<Highcharts.Options>(() => ({
    chart: {
      type: 'scatter',
      height: 250,
      zoomType: 'x',
      style: {
        fontFamily: 'sans-serif',
      },
    },
    colors: ['#058DC7', '#50B432', '#ED561B', '#5749AD', '#D73A49'],
    title: {
      text: activity.name,
    },
    xAxis: {
      type: 'datetime',
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      min: new Date(activity.from).getTime(),
      max: new Date(activity.to).getTime(),
      labels: {
        formatter(ctx) {
          return moment(ctx.value).format(convertDateTimeLabelFormat(ctx.dateTimeLabelFormat));
        },
      },
    },
    yAxis: {
      title: null,
      labels: {
        enabled: false,
      },
      tickInterval: 1,
      gridLineDashStyle: 'Dash',
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 2.5,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true,
              lineColor: '#666'
            }
          }
        },
      }
    },
    series: activity.machines.map((machine, i) => ({
      type: 'scatter',
      name: machine.machineId,
      data: machine.activities.map((timestamp) => ({
        x: new Date(timestamp).getTime(),
        y: i + 1,
      }))
    })),
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter() {
        return `<b>${this.series.name}</b><br/>${moment(this.x).format('LLL')}`;
      },
    },
  }), [activity]);

  return (
    <div className={className}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default ActivityChart;

export class KeyModel {
  id: number;
  userId: number;
  encKey: string;
  decKey: string;

  constructor(init: any = {}) {
    const data = {
      id: null,
      userId: null,
      encKey: '',
      decKey: '',
      ...init,
    };

    this.id = data.id;
    this.userId = data.userId;
    this.encKey = data.encKey;
    this.decKey = data.decKey;
  }
}

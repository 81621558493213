import React, {FC} from "react";
import {Select as MaterialSelect} from "@mui/material";
import {IAbstractInputControlProps, InputWrapper} from "../ReactiveForm";
import classnames from "classnames";

export interface ISelectProps {
  value?: any;
  placeholder?: string;
}

export const Select: FC<ISelectProps & IAbstractInputControlProps> = ({
  name = '',
  value,
  placeholder = '',
  onChange = () => {},
  children,
  ...wrapperProps
}) => {
  return (
    <InputWrapper {...wrapperProps}>
      {({ className, containerClass, disabled, onFocus, onBlur }) => (
        <MaterialSelect
          className={containerClass}
          classes={{ select: className }}
          name={name}
          value={value || ''}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
        >
          {children}
        </MaterialSelect>
      )}
    </InputWrapper>
  );
};

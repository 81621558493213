import moment from "moment";

export const formatDuration = (seconds: number, format = 'hh:mm') => {
  if (seconds < 0) {
    seconds = 0;
  }
  if (format === 'auto') {
    format = seconds < 3600 ? 'm:ss' : 'h:m:ss';
  }

  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 60);

  const hh = `${h < 10 ? '0' : ''}${h}`;
  const mm = `${m < 10 ? '0' : ''}${m}`;
  const ss = `${s < 10 ? '0' : ''}${s}`;

  return format
    .replace('hh', hh)
    .replace('mm', mm)
    .replace('ss', ss)
    .replace('h', h.toString())
    .replace('m', m.toString())
    .replace('s', s.toString());
};

export const fromNow = (date: string | Date, blankText = 'never') => {
  if (!date) {
    return blankText;
  }
  const diff = moment().diff(moment(date), 'seconds');
  if (diff < 60) {
    return `${diff} seconds ago`;
  }
  return moment(date).fromNow();
};

export const getTimezone = () => {
  const offset = moment().utcOffset();
  if (!offset) {
    return 'UTC';
  }
  let zone =  formatDuration(offset * 60, 'hh:mm');
  if (offset > 0) {
    zone = '+' + zone;
  }
  return zone;
};

export const convertDateTimeLabelFormat = (dateTimeLabelFormat: string) => {
  return dateTimeLabelFormat
    .replace('%H', 'HH')
    .replace('%M', 'mm')
    .replace('%e', 'D')
    .replace('%b', 'MMM')
    .replace('%y', 'YY');
};

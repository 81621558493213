import {HttpService} from "./http.service";
import {ScreenshotService} from "./screenshot.service";
import {decryptAES, decryptRSA, encryptRSA, formatPaginatedResult, getMasterKey} from "../utils/helpers";
import {ScreenshotModel, UserModel} from "../utils/types";

export class UserService {

  static async search(filter: any = {}, showSpinner = true) {
    return HttpService.get('/admin/user', filter, {}, showSpinner)
      .then((data) => formatPaginatedResult(UserModel, data));
  }

  static async find(id: number, showSpinner = true) {
    return HttpService.get(`/admin/user/${id}`, {}, {}, showSpinner)
      .then((data) => new UserModel(data));
  }

  static async create(data, showSpinner = true) {
    return HttpService.post('/admin/user', data, {}, showSpinner)
      .then((data) => new UserModel(data));
  }

  static async update(id: number, data, showSpinner = true) {
    return HttpService.patch(`/admin/user/${id}`, data, {}, showSpinner)
      .then((data) => new UserModel(data));
  }

  static async delete(id: number, showSpinner = true) {
    return HttpService.delete(`/admin/user/${id}`, {}, {}, showSpinner);
  }

  static async deleteMany(ids: number[], showSpinner = true) {
    return HttpService.delete(`/admin/user`, {ids}, {}, showSpinner);
  }

  static async setKey(user: UserModel, encryptKey: string, decryptKey: string, resetKeys = true, showSpinner = true) {
    if (user.decKey && resetKeys) {
      const files = await UserService.getFiles(user.id);
      if (files.length) {
        const updates = [];
        const masterKey = await getMasterKey();
        const privateKey = decryptAES(user.decKey, masterKey);
        files.forEach((file) => {
          if (!file.aesKey) {
            return;
          }

          const aesKey = decryptRSA(file.aesKey, privateKey);
          if (!aesKey) {
            return;
          }
          const encryptedAesKey = encryptRSA(aesKey, encryptKey);
          updates.push({
            uuid: file.uuid,
            aesKey: encryptedAesKey,
          });
        });

        await ScreenshotService.updateAll(updates);
      }
    }
    return HttpService.post(`/admin/user/${user.id}/key`, { encryptKey, decryptKey }, {}, showSpinner);
  }

  static async getFiles(id: number, showSpinner = true) {
    return HttpService.get(`/admin/user/${id}/files`, {}, {}, showSpinner)
      .then((data) => data.map((item) => new ScreenshotModel(item)));
  }

  static async downloadKeys(ids: number[], showSpinner = true) {
    return HttpService.post(`/admin/user/keys`, {ids}, {}, showSpinner);
  }
}

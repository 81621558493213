import React, {FC} from "react";
import classnames from "classnames";

export enum MONITOR_MODE {
  LIVE,
  HISTORY,
}

export interface IModeSwitchProps {
  className?: string;
  value: MONITOR_MODE;
  onToggle(): void;
}

const ModeSwitch: FC<IModeSwitchProps> = ({
  className,
  value,
  onToggle,
}) => {
  return (
    <div className={classnames('w-36 h-6 relative flex-center border border-primary rounded-xl cursor-pointer', className)} onClick={onToggle}>
      <div className={classnames(
        'absolute w-1/2 h-6 bg-primary rounded-xl transition-all',
        value === MONITOR_MODE.LIVE ? 'left-0' : 'right-0',
      )} />
      <div className={classnames(
        'relative w-1/2 text-xs font-bold text-center',
        value === MONITOR_MODE.LIVE ? 'text-white' : 'text-primary',
      )}>
        Live
      </div>
      <div className={classnames(
        'relative w-1/2 text-xs font-bold text-center',
        value === MONITOR_MODE.HISTORY ? 'text-white' : 'text-primary',
      )}>
        History
      </div>
    </div>
  );
};

export default ModeSwitch;

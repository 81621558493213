import React, {FC, useEffect, useState} from "react";
import moment from "moment";
import {BounceLoader} from "react-spinners";
import ActivityChart from "../ActivityChart";
import {ActivitiesService} from "../../../services";
import {IUserActivity} from "../../../utils/interfaces";
import {UserModel} from "../../../utils/types";

export interface IActivityCardProps {
  className?: string;
  user: UserModel;
  startDate: Date;
  endDate: Date;
}

const ActivityCard: FC<IActivityCardProps> = ({
  user,
  startDate,
  endDate,
}) => {
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState<IUserActivity>();

  useEffect(() => {
    setLoading(true);
    ActivitiesService.search({
      userId: user.id,
      from: moment(startDate).startOf('day').toISOString(),
      to: moment(endDate).endOf('day').toISOString(),
    }, false).then((res) => {
      setActivity(res.data[0]);
    }).catch(() => {
      setActivity(undefined);
    }).finally(() => {
      setLoading(false);
    });
  }, [user.id, startDate, endDate]);

  if (!activity) {
    return (
      <div className="relative h-74 bg-white rounded shadow p-6 flex flex-col">
        <div className="text-xl text-center">{user.name}</div>
        {loading && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white/60">
            <BounceLoader color="#0B1C34" size={40}/>
          </div>
        )}
      </div>
    );
  }

  return (
    <ActivityChart
      className="bg-white rounded shadow p-6"
      activity={activity}
    />
  );
};

export default ActivityCard;

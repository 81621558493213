import React, {FC, useEffect, useState} from "react";
import classnames from "classnames";
import {BounceLoader} from "react-spinners";
import {TablePagination} from "@mui/material";
import {IUserMachine} from "../History";
import {MONITOR_MODE} from "../ModeSwitch";
import ScreenshotCard from "../ScreenshotCard";
import PreviewModal from "../PreviewModal";
import {ScreenshotService, ToastService} from "../../../services";
import {ScreenshotModel} from "../../../utils/types";

export interface IMachineHistoryViewProps {
  machine: IUserMachine;
  filter: any;
  perPage?: number;
  showUsername?: boolean;
}

const MachineHistoryView: FC<IMachineHistoryViewProps> = ({
  machine,
  filter,
  perPage = 12,
  showUsername = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(true);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [files, setFiles] = useState<ScreenshotModel[]>([]);
  const [preview, setPreview] = useState<ScreenshotModel>();

  useEffect(() => {
    setPage(0);
  }, [machine]);

  useEffect(() => {
    setLoading(true);
    ScreenshotService.searchHistories({
      ...filter,
      userId: machine.user.id,
      machineId: machine.machineId,
      skip: page * perPage,
      limit: perPage,
    }, false).then((res) => {
      setFiles(res.data);
      setTotalCount(res.totalCount);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading screenshots failed');
    }).finally(() => {
      setLoading(false);
    });
  }, [machine, page, filter]);

  const onToggleOpen = () => {
    setOpened((opened) => !opened);
  };

  return (
    <>
      <div key={machine.machineId} className="mt-4">
        <div className="flex items-center text-xs cursor-pointer" onClick={onToggleOpen}>
          <span className="font-bold">
            {showUsername && (
              <span><b className="text-sm">{machine.user.name}</b> - </span>
            )}
            {machine.machineId.substr(-6)} ({files.length})
            <span className="text-gray font-normal ml-2">{machine.user.appVersion}</span>
          </span>
          <i className={classnames(
            'fa fa-caret-right text-sm ml-2 transition-all',
            opened && 'rotate-90',
          )} />
          <div className="flex-grow border-t border-gray-d9 ml-2" />
        </div>

        {opened && (
          <div className="relative min-h-28">
            {files.length > 0 && (
              <>
                <div
                  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-4 mt-4">
                  {files.map((item, i) => (
                    <ScreenshotCard
                      key={i}
                      className="h-[30vw] sm:h-[17vw] md:h-[13vw] lg:h-[8vw]"
                      mode={MONITOR_MODE.HISTORY}
                      screenshot={item}
                      decKey={machine.user.decKey}
                      onPreview={() => setPreview(item)}
                    />
                  ))}
                </div>
                {totalCount > perPage && (
                  <TablePagination
                    component="div"
                    className="mt-2 -mb-6"
                    count={totalCount}
                    page={page}
                    rowsPerPage={perPage}
                    showFirstButton
                    showLastButton
                    rowsPerPageOptions={[perPage]}
                    onPageChange={(_, page) => setPage(page)}
                  />
                )}
              </>
            )}

            {!files.length && !loading && (
              <div className="w-full h-full flex items-center justify-center text-center text-gray">
                No screenshots are available
              </div>
            )}

            {loading && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white/60">
                <BounceLoader color="#0B1C34" size={40}/>
              </div>
            )}
          </div>
        )}
      </div>

      {preview && (
        <PreviewModal
          file={preview}
          files={files}
          decKey={machine.user.decKey}
          onClose={() => setPreview(undefined)}
        />
      )}
    </>
  );
};

export default MachineHistoryView;

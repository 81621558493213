import React from "react";
import {useSelector} from "react-redux";
import {BounceLoader} from "react-spinners";
import {getLoadingSpinners} from "../../redux/selectors";

export const SpinnerContainer = () => {
  const loadingCount = useSelector(getLoadingSpinners);

  return (
    <>
      {loadingCount > 0 && (
        <div className="fixed top-0 left-0 z-max w-screen h-screen bg-white bg-opacity-60 flex-center">
          <BounceLoader color="#0B1C34" size={60} />
        </div>
      )}
    </>
  );
};

import {MESSAGE_BOX_ACTION} from '../action-types';
import {IConfirmationModalProps} from "../../components";

export interface IMessageBoxState {
  confirmModalOptions?: IConfirmationModalProps;
}

const initialState: IMessageBoxState = {
  confirmModalOptions: undefined,
};

const messageBoxReducer = (state: IMessageBoxState = initialState, action: any) => {
  switch (action.type) {
    case MESSAGE_BOX_ACTION.SHOW_CONFIRM_MODAL:
      return {
        ...state,
        confirmModalOptions: action.options,
      };

    case MESSAGE_BOX_ACTION.CLOSE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModalOptions: undefined,
      };

    default:
      return state;
  }
};

export default messageBoxReducer;

import {UserModel} from "./user.model";

export class ScreenshotModel {
  id: number;
  uuid: string;
  timestamp: string;
  version: string;
  machineId: string;
  aesKey: string;
  user?: UserModel;

  constructor(init: any = {}) {
    const data = {
      id: null,
      uuid: null,
      timestamp: null,
      version: '',
      machineId: '',
      aesKey: '',
      user: null,
      ...init,
    };

    this.id = data.id;
    this.uuid = data.uuid;
    this.timestamp = data.timestamp;
    this.version = data.version;
    this.machineId = data.machineId;
    this.aesKey = data.aesKey;
    if (data.user) {
      this.user = new UserModel(data.user);
    }
  }
}

import {ROUTES} from "./routes";
import {Role} from "../utils/enums";

export interface MenuItem {
  text: string;
  icon: string;
  prefix: string;
  link: string;
  roles?: Role[];
}

export const MENUS: MenuItem[] = [
  { text: 'Users', icon: 'fa fa-user', prefix: ROUTES.USERS.PREFIX, link: ROUTES.USERS.INDEX },
  { text: 'Teams', icon: 'fa fa-group', prefix: ROUTES.TEAMS.PREFIX, link: ROUTES.TEAMS.INDEX, roles: [Role.ADMIN] },
  { text: 'Team Managers', icon: 'fa fa-user-tie', prefix: ROUTES.TEAM_MANAGERS.PREFIX, link: ROUTES.TEAM_MANAGERS.INDEX, roles: [Role.ADMIN] },
  { text: 'Screenshots', icon: 'fa fa-desktop', prefix: ROUTES.SCREENSHOTS.PREFIX, link: ROUTES.SCREENSHOTS.INDEX },
  { text: 'Activities', icon: 'fa fa-line-chart', prefix: ROUTES.ACTIVITIES.PREFIX, link: ROUTES.ACTIVITIES.INDEX },
  { text: 'Settings', icon: 'fa fa-cog', prefix: ROUTES.SETTINGS.PREFIX, link: ROUTES.SETTINGS.INDEX, roles: [Role.ADMIN]},
];

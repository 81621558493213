import React, {FC, ReactElement, useMemo} from "react";
import classnames from "classnames";
import {Menu as MuiMenu, MenuItem as MuiMenuItem, PopoverProps} from "@mui/material";
import {Button, IButtonProps} from "../Button";

export type IMenuItem = {
  icon?: ReactElement;
  text: string | ReactElement;
  disabled?: boolean;
} & {
  [key: string]: any;
};

export interface IMenuProps extends Omit<PopoverProps, 'open'> {
  className?: string;
  text?: string | ReactElement;
  button?: ReactElement;
  buttonProps?: IButtonProps;
  arrow?: ReactElement | null;
  items: IMenuItem[];
  menuClass?: string;
  menuItemClass?: string;
  activeMenuItemClass?: string;
  disabled?: boolean;
  activeItem?: IMenuItem;
  onSelectItem?: (item: IMenuItem) => void;
}

export const Menu: FC<IMenuProps> = ({
  className = '',
  text = '',
  button,
  buttonProps = {},
  arrow,
  items,
  menuClass = '',
  menuItemClass = '',
  activeMenuItemClass = 'font-bold',
  disabled,
  activeItem,
  onSelectItem = () => {},
  ...menuProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opened = Boolean(anchorEl);

  const arrowIcon = useMemo(() => {
    if (arrow === null)
      return undefined;

    if (buttonProps?.rightIcon)
      return buttonProps.rightIcon;

    if (arrow) {
      return (
        <div className={classnames('transform transition-transform', { 'rotate-180': opened })}>
          {arrow}
        </div>
      );
    }
    return (
      <i className={classnames('fa fa-caret-down transform transition-transform', { 'rotate-180': opened })} />
    );
  }, [arrow, buttonProps, opened]);

  const onOpen = (e: React.MouseEvent<HTMLElement>) => {
    if (items.length) {
      setAnchorEl(e.currentTarget);
    }
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (item: IMenuItem) => {
    if (item !== activeItem) {
      onSelectItem(item);
    }
    onClose();
  };

  return (
    <div className={classnames('relative transition-all', className)}>
      {button ? (
        <div onClick={onOpen}>{button}</div>
      ) : (
        <Button
          {...buttonProps}
          className={classnames(
            '!justify-between',
            buttonProps.className,
          )}
          rightIcon={arrowIcon}
          rightIconClass={classnames(buttonProps.rightIconClass, '!ml-auto pl-2')}
          disabled={disabled}
          onClick={onOpen}
        >
          {text}
        </Button>
      )}

      <MuiMenu
        classes={{
          root: menuClass,
        }}
        anchorEl={anchorEl}
        open={opened}
        onClose={onClose}
        {...menuProps}
      >
        {items.map((item, i) => (
          <MuiMenuItem
            key={i}
            classes={{
              root: menuItemClass,
              selected: activeMenuItemClass,
            }}
            selected={item === activeItem}
            disabled={item.disabled}
            onClick={() => onSelect(item)}
          >
            {item.icon}
            <span>{item.text}</span>
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </div>
  );
};

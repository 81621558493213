import React from "react";
import store from "../../redux/store";
import {getAccount} from "../../redux/selectors";
import {MessageBoxService, ToastService} from "../../services";
import {setAccount} from "../../redux/actions";
import {AccountModel} from "../types";

export const copyToClipboard = async (text: string, format = 'text/plain', isToast = true): Promise<void> => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
    if (isToast) {
      ToastService.success('Successfully copied to clipboard.');
    }
  } else {
    const _copyToClipboard = (e) => {
      e.preventDefault();
      e.clipboardData.setData(format, text);
      document.removeEventListener('copy', _copyToClipboard);
      if (isToast) {
        ToastService.success('Successfully copied to clipboard.');
      }
    };
    document.addEventListener('copy', _copyToClipboard);
    document.execCommand('copy');
  }
};

export const saveAs = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const el = document.createElement('a');
  el.href = url;
  el.target = '_blank';
  el.download = filename;
  el.style.display = 'none';
  document.body.appendChild(el);
  el.click();

  setTimeout(() => {
    el.remove();
  }, 1000);
};

export const fround = (value: number, precision = 2) => {
  const mul = Math.pow(10, precision);
  return Math.round(value * mul) / mul;
};

export const trimObject = (obj, filters = [undefined, null]) => {
  if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
    return obj;
  }

  const result = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (!filters.includes(value)) {
      result[key] = value;
    }
  });

  return result;
};

export const getMasterKey = async () => {
  const account = getAccount(store.getState());
  let masterKey = account.masterKey;
  if (masterKey) {
    return masterKey;
  }

  masterKey = await MessageBoxService.confirm({
    type: 'master-key',
    title: 'Enter master key',
    message: 'Please enter master key to encrypt RSA keys.',
    confirmButtonText: 'OK',
  }) as string;

  if (!masterKey) {
    return;
  }

  store.dispatch(setAccount(new AccountModel({
    ...account,
    masterKey,
  })));

  return masterKey;
};

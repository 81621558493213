import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {ROUTES} from "../../constants";
import Home from "./index";

const ActivitiesRouting = () => (
  <Switch>
    <Route path={ROUTES.ACTIVITIES.INDEX} component={Home} />
    <Redirect to={ROUTES.ACTIVITIES.INDEX} />
  </Switch>
);

export default ActivitiesRouting;

import React, {FC} from "react";
import {useSelector} from "react-redux";
import {getAccount} from "../../../redux/selectors";
import {AuthService} from "../../../services";
import {formatDateTime} from "../../../utils/helpers";

interface IFullLayoutHeaderProps {
  sidebarCollapsed: boolean;
  onToggleSidebar(): void;
}

const FullLayoutHeader: FC<IFullLayoutHeaderProps> = ({
  onToggleSidebar,
}) => {
  const account = useSelector(getAccount);

  const onLogout = () => {
    AuthService.logout();
  };

  return (
    <div className="sticky top-0 left-0 z-20 flex items-center bg-white text-primary shadow-md px-6 py-3">
      <i
        className="fa fa-bars text-lg cursor-pointer mr-auto"
        onClick={onToggleSidebar}
      />
      {account?.lastLoggedIn && (
        <div className="text-xs ml-8">
          Last Login: {formatDateTime(account.lastLoggedIn, 'ddd, MM.DD.YYYY h:mm A')}
        </div>
      )}
      <i
        className="fa fa-sign-out-alt text-2xl cursor-pointer ml-8"
        onClick={onLogout}
      />
    </div>
  );
};

export default FullLayoutHeader;

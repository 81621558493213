import React, {FC} from "react";
import classnames from "classnames";
import {Autocomplete as MuiAutocomplete, AutocompleteProps, TextField} from "@mui/material";
import {IAbstractInputControlProps, InputWrapper} from "../ReactiveForm";

export interface IAutocompleteProps extends Omit<AutocompleteProps<any, boolean, boolean, boolean>, 'size' | 'renderInput' | 'onChange'> {
}

export const Autocomplete: FC<IAutocompleteProps & IAbstractInputControlProps> = ({
  containerClass,
  name = '',
  placeholder = '',
  size,
  labelClass,
  error,
  helperText,
  onChange,
  ...wrapperProps
}) => {
  return (
    <InputWrapper
      {...wrapperProps}
      size={size}
      containerClass={containerClass}
      labelClass={labelClass}
      error={error}
      helperText={helperText}
    >
      {({ className, containerClass, disabled, onFocus, onBlur }) => (
        <MuiAutocomplete
          {...wrapperProps}
          className={containerClass}
          classes={{ inputRoot: classnames('min-h-9 !py-0', className), option: `text-${size}`, tag: '!h-7' }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                className: classnames(params.inputProps?.className, '!py-0')
              }}
              placeholder={placeholder}
            />
          )}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(_, value: any) => onChange(value)}
        />
      )}
    </InputWrapper>
  );
};

import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {Button} from "../../../components";
import {Form, FormControl, Input, useForm} from "../../../components/ReactiveForm";
import {setAccount, setToken} from "../../../redux/actions";
import {AuthService, ToastService} from "../../../services";
import {checksum, decryptAES} from "../../../utils/helpers";
import {AccountModel, UserModel} from "../../../utils/types";
import {ROUTES} from "../../../constants";

const Login = () => {
  const [form] = useForm<{
    username: FormControl;
    password: FormControl;
    masterKey: FormControl;
  }>({
    username: new FormControl(''),
    password: new FormControl(''),
    masterKey: new FormControl(''),
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [user, setUser] = useState<UserModel>();
  const [accessToken, setAccessToken] = useState<string>();
  const [kData, setKData] = useState<string>();
  const [lastLoggedIn, setLastLoggedIn] = useState<string>();

  const onLogin = (formData) => {
    const { username, password } = formData;
    if (!username || !password) {
      return;
    }

    AuthService.login(formData.username, formData.password).then(({ user, accessToken, kData, lastLoggedIn }) => {
      setUser(new UserModel(user));
      setAccessToken(accessToken);
      setKData(kData);
      setLastLoggedIn(lastLoggedIn);
      setStep(2);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Login failed');
    });
  };

  const onLoginWithMasterKey = async (formData) => {
    const { masterKey } = formData;
    if (!masterKey) {
      return;
    }

    try {
      const decryptedKData = decryptAES(kData, masterKey);
      const crc32 = checksum(decryptedKData);
      const { status } = await AuthService.loginWithMasterKey(crc32);
      if (status) {
        dispatch(setToken(accessToken));
        dispatch(setAccount(new AccountModel({ user, lastLoggedIn, masterKey, kData })));
        history.push(ROUTES.USERS.INDEX);
      } else {
        ToastService.error('Login failed');
      }
    } catch (err) {
      ToastService.error('Login failed');
    }
  };

  return (
    <Form formGroup={form} onSubmit={step === 1 ? onLogin : onLoginWithMasterKey}>
      <h1 className="text-3xl text-primary text-center font-bold">Login</h1>

      <div className="mt-8">
        {step === 1 ? (
          <>
            <Input
              className="!border-info"
              control={form.controls.username}
              type="text"
              placeholder="User Name"
              fullWidth
              lpIgnore={false}
            />

            <Input
              className="!border-info mt-4"
              control={form.controls.password}
              type="password"
              placeholder="Password"
              fullWidth
              lpIgnore={false}
            />
          </>
        ) : (
          <Input
            control={form.controls.masterKey}
            type="password"
            className="!border-info"
            fullWidth
            lpIgnore={false}
          />
        )}
      </div>

      <div className="flex-center mt-8">
        <Button type="submit" color="primary" className="px-8">Login</Button>
      </div>
    </Form>
  );
};

export default Login;

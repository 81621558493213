import React, {MouseEventHandler, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from "moment";
import {ROUTES} from "../../constants";
import {getSettings} from "../../redux/selectors";

export const ResetKeyReminder = () => {
  const settings = useSelector(getSettings);
  const [show, setShow] = useState(true);

  const shouldShowNotification = useMemo(() => {
    const lastKeyUpdate = settings.lastKeyUpdated ? moment(settings.lastKeyUpdated) : moment(settings.createdAt);
    return moment().diff(lastKeyUpdate, 'days') >= settings.resetPasswordReminderDays;
  }, [settings]);

  const onClose: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(false);
  };

  if (!show || !shouldShowNotification) {
    return null;
  }

  return (
    <Link to={ROUTES.SETTINGS.INDEX}>
      <div className="fixed bottom-4 right-4 z-max bg-danger-light text-white rounded-md shadow-md px-6 py-4 cursor-pointer">
        <i className="fa fa-exclamation-triangle mr-2" />
        It's time to reset master key.

        <i className="fa fa-times ml-8" onClick={onClose} />
      </div>
    </Link>
  );
};

import React, {FC, useEffect, useState} from "react";
import classnames from "classnames";
import {MONITOR_MODE} from "../ModeSwitch";
import ScreenshotView from "../ScreenshotView";
import {ScreenshotModel} from "../../../utils/types";
import {formatDateTime, fromNow} from "../../../utils/helpers";

export interface IScreenshotCardProps {
  className?: string;
  mode: MONITOR_MODE;
  screenshot: ScreenshotModel;
  decKey: string;
  onPreview(): void;
}

const ScreenshotCard: FC<IScreenshotCardProps> = ({
  className,
  mode,
  screenshot,
  decKey,
  onPreview,
}) => {
  const [timestamp, setTimestamp] = useState(new Date());

  useEffect(() => {
    if (mode !== MONITOR_MODE.LIVE) {
      return;
    }
    const timer = setInterval(() => {
      setTimestamp(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [mode]);

  const onClickPreview = () => {
    if (screenshot.uuid) {
      onPreview();
    }
  };

  return (
    <div
      className={classnames('group relative w-full rounded-md shadow-md cursor-pointer overflow-hidden', className)}
      onClick={onClickPreview}
    >
      {screenshot.uuid ? (
        <div className="w-full h-full flex-center">
          <ScreenshotView screenshot={screenshot} decKey={decKey} />
        </div>
      ) : (
        <div className="w-full h-full flex-center text-gray text-center pb-8">
          No screenshot available
        </div>
      )}

      {mode === MONITOR_MODE.LIVE && (
        <div className="absolute w-full bg-white bottom-0 flex text-black text-xs truncate font-bold border-t border-gray-d9 px-4 py-2">
          <span>{screenshot.user?.name || ''}</span>
          {screenshot.timestamp && (
            <span className="ml-auto">
              {fromNow(screenshot.timestamp, '')}
            </span>
          )}
        </div>
      )}
      {mode === MONITOR_MODE.HISTORY && screenshot.timestamp && (
        <div className="absolute w-full bg-white bottom-0 text-black text-xs truncate border-t border-gray-d9 px-3 py-1">
          {formatDateTime(screenshot.timestamp, 'h:mm A, D MMM YYYY')}
        </div>
      )}

      {screenshot.id && (
        <div className={classnames(
          'absolute w-full h-full top-0 left-0 flex-center bg-black bg-opacity-60 backdrop-blur-[1px]',
          'opacity-0 group-hover:opacity-100 transition-all'
        )}>
          <i className={classnames(
            'fa fa-eye text-white',
            mode === MONITOR_MODE.LIVE ? 'fa-3x' : 'fa-2x',
          )} />
        </div>
      )}
    </div>
  );
};

export default ScreenshotCard;

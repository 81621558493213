import React, {FC, ReactElement, useState} from "react";
import {Button, TextField, Modal, Input} from "../index";
import {FormControl, Validators} from "../ReactiveForm";

export type ConfirmType = 'default' | 'passcode' | 'email' | 'master-key';

export interface IConfirmationModalProps {
  type?: ConfirmType;
  title?: string | ReactElement;
  message?: string | ReactElement;
  confirmMessage?: string | ReactElement;
  passcode?: string;
  confirmButtonText?: string;
  onClose?: (confirm: boolean | string) => void;
}

export const ConfirmationModal: FC<IConfirmationModalProps> = ({
  type = 'default',
  title = 'Confirmation',
  message,
  confirmMessage,
  passcode,
  confirmButtonText = 'Confirm',
  onClose = () => {},
}) => {
  const [code, setCode] = useState('');
  const [emailInput] = useState(new FormControl('', [Validators.required(), Validators.email()]));
  const [masterKeyInput] = useState(new FormControl('', [Validators.required()]));

  const onConfirm = () => {
    if (type === 'default') {
      onClose(true);
    } else if (type === 'email') {
      if (emailInput.validate()) {
        onClose(emailInput.value);
      }
    } else if (type === 'master-key') {
      if (masterKeyInput.validate()) {
        onClose(masterKeyInput.value);
      }
    }
  };

  return (
    <Modal
      size="sm"
      title={title}
      overrideParentModal
      footerComponent={(
        <>
          <Button color="primary" variant="outline" className="ml-auto" onClick={() => onClose(false)}>Cancel</Button>
          {(type === 'default' || type === 'email' || type === 'master-key') && (
            <Button
              color="primary"
              className="min-w-20 ml-4"
              onClick={onConfirm}
            >
              {confirmButtonText}
            </Button>
          )}
          {type === 'passcode' && (
            <Button
              color="primary"
              className="min-w-20 ml-4"
              disabled={passcode && code !== passcode}
              onClick={() => onClose(true)}
            >
              {confirmButtonText}
            </Button>
          )}
        </>
      )}
      onClose={() => onClose(false)}
    >
      {message && (
        <p className="text-sm">{message}</p>
      )}
      {confirmMessage && (
        <p className="text-sm mt-2">{confirmMessage}</p>
      )}
      {type === 'passcode' && confirmMessage !== null && (
        <p className="text-sm mt-2">If you are sure, please type <b>'{passcode}'</b> in the text box below.</p>
      )}
      {type === 'passcode' && (
        <TextField
          containerClass="mt-4"
          fullWidth
          value={code}
          placeholder={passcode}
          onChange={setCode}
        />
      )}
      {type === 'email' && (
        <Input
          control={emailInput}
          containerClass="mt-4"
          fullWidth
          placeholder="Enter your email address"
        />
      )}
      {type === 'master-key' && (
        <Input
          control={masterKeyInput}
          containerClass="mt-4"
          fullWidth
        />
      )}
    </Modal>
  );
}

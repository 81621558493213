export class TeamModel {
  id: number;
  name: string;
  userCount: number;
  createdAt: string;
  updatedAt: string;

  constructor(init: any = {}) {
    const data = {
      id: null,
      name: '',
      userCount: 0,
      createdAt: '',
      updatedAt: '',
      ...init,
    };

    this.id = data.id;
    this.name = data.name;
    this.userCount = data.userCount;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import moment from "moment";
import {BounceLoader} from "react-spinners";
import ModeSwitch, {MONITOR_MODE} from "../ModeSwitch";
import MachineHistoryView from "../MachineHistoryView";
import {Button, DateRagePicker, UserSelect} from "../../../components";
import {ROUTES} from "../../../constants";
import {getAccount} from "../../../redux/selectors";
import {MessageBoxService, ScreenshotService, ToastService} from "../../../services";
import {Role} from "../../../utils/enums";
import {UserModel} from "../../../utils/types";

export interface IUserMachine {
  user: UserModel;
  machineId: string;
}

const ScreenshotHistories = () => {
  const history = useHistory();
  const account = useSelector(getAccount);
  const isSuperAdmin = account.user.role === Role.ADMIN;

  const [users, setUsers] = useState<UserModel[]>([]);
  const [filter, setFilter] = useState({
    users: [],
    date: [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
  });
  const [loading, setLoading] = useState(false);
  const [machines, setMachines] = useState<IUserMachine[]>([]);

  const filteredMachines = useMemo(() => {
    if (!filter.users.length) {
      return machines;
    }
    return machines.filter((machine) => filter.users.includes(machine.user.id));
  }, [machines, filter.users]);

  const loadMachines = useCallback(() => {
    if (!users.length) {
      return;
    }

    setLoading(true);
    ScreenshotService.getAvailableMachines({
      startDate: moment(filter.date[0]).startOf('day').toISOString(),
      endDate: moment(filter.date[1]).endOf('day').toISOString(),
    }, false).then((res) => {
      const machines: IUserMachine[] = [];
      res.forEach((item) => {
        const user = users.find((u) => u.id === item.userId);
        if (user) {
          item.machineIds.forEach((machineId) => {
            machines.push({
              user,
              machineId,
            });
          });
        }
      });
      setMachines(machines);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading machines failed');
      setMachines([]);
    }).finally(() => {
      setLoading(false);
    });
  }, [users, filter]);

  useEffect(() => {
    loadMachines();
  }, [loadMachines]);

  const onFilterChange = (field: string, value: any) => {
    setFilter((filter) => ({
      ...filter,
      [field]: value,
    }));
  };

  const onSwitchMode = () => {
    history.push(ROUTES.SCREENSHOTS.LIVE);
  };

  const onDeleteAll = () => {
    MessageBoxService.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to delete all filtered screenshots?',
    }).then((result) => {
      if (result) {
        ScreenshotService.deleteAll({
          userIds: filter.users.length ? filter.users : [],
          startDate: moment(filter.date[0]).startOf('day').toISOString(),
          endDate: moment(filter.date[1]).endOf('day').toISOString(),
        }).then(() => {
          ToastService.success('Successfully deleted screenshots');
          setMachines([]);
        }).catch((err) => {
          ToastService.showHttpError(err, 'Deleting screenshots failed');
        });
      }
    });
  };

  return (
    <>
      <div className="px-8 py-10">
        <div className="flex items-center">
          <h1 className="text-primary text-xl font-bold">Screen Monitoring</h1>
          <div className="ml-2">({users.length} Members)</div>
          <Button
            className="ml-auto mr-8"
            color="primary"
            variant="outline"
            onClick={loadMachines}
          >
            <i className="fa fa-refresh mr-2" /> Refresh
          </Button>
          <ModeSwitch value={MONITOR_MODE.HISTORY} onToggle={onSwitchMode} />
        </div>

        <div className="bg-white rounded shadow p-6 mt-4">
          <div className="flex flex-wrap items-center gap-4">
            <UserSelect
              className="flex-1"
              value={filter.users}
              onChange={(userIds) => onFilterChange('users', userIds)}
              onLoadUsers={setUsers}
            />

            <DateRagePicker
              size="sm"
              name="date"
              label="Date Range"
              containerClass="w-full md:w-60"
              fullWidth
              value={filter.date}
              onChange={(e) => onFilterChange('date', e)}
            />

            {isSuperAdmin && machines.length > 0 && (
              <Button
                className="max-md:w-full self-end ml-auto mb-1"
                color="danger"
                variant="outline"
                onClick={onDeleteAll}
              >
                <i className="fa fa-trash mr-2"/> Delete All
              </Button>
            )}
          </div>

          <div className="mt-6">
            {loading ? (
              <div className="flex-center h-80">
                <BounceLoader color="#0B1C34" size={60}/>
              </div>
            ) : (
              filteredMachines.map((machine, i) => (
                <MachineHistoryView
                  key={i}
                  machine={machine}
                  filter={{
                    startDate: moment(filter.date[0]).startOf('day').toISOString(),
                    endDate: moment(filter.date[1]).endOf('day').toISOString(),
                  }}
                />
              ))
            )}
          </div>

          {!loading && !machines.length && (
            <div className="flex-center text-center h-80 px-4 py-8">No screenshots found.</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ScreenshotHistories;

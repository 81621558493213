import React, {FC, useCallback, useEffect} from "react";
import {Input} from "../../../components";
import {Form, FormControl, useForm, Validators} from "../../../components/ReactiveForm";
import {TeamModel} from "../../../utils/types";

export interface ITeamFormInitParams {
  getFormData: () => Promise<any>;
}

export interface ITeamFormProps {
  team?: TeamModel;
  onInit?: (params: ITeamFormInitParams) => void;
}

export type ITeamForm = {
  name: FormControl;
}

const TeamForm: FC<ITeamFormProps> = ({
  team,
  onInit = () => {},
}) => {
  const [form] = useForm<ITeamForm>({
    name: new FormControl('', [Validators.required()]),
  });

  useEffect(() => {
    if (team) {
      form.patch({
        name: team.name,
      });
    }
  }, [team]);

  const getFormData = useCallback(async () => {
    if (!form.validate()) {
      return null;
    }
    return form.getFormData();
  }, [form]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  return (
    <Form formGroup={form}>
      <Input
        control={form.controls.name}
        size="sm"
        fullWidth
        label="Team"
        placeholder="Team name"
      />
    </Form>
  );
};

export default TeamForm;

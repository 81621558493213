import React from "react";
import {Redirect, Switch} from "react-router-dom";
import {ROUTES} from "../../constants";
import AuthRoute from "../../routes/AuthRoute";
import Login from "./Login";

const AuthRouting = () => (
  <Switch>
    <AuthRoute path={ROUTES.AUTH.LOGIN} component={Login} />
    <Redirect to={ROUTES.AUTH.LOGIN} />
  </Switch>
);

export default AuthRouting;

import React from "react";

const AuthLayout = ({
  children,
}) => {
  return (
    <div className="w-screen h-screen bg-auth bg-cover bg-center bg-no-repeat flex-center">
      <div className="w-120 max-w-[95vw] bg-info-lighter border border-info rounded-md px-8 pt-8 pb-6">
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;

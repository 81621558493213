import React, {FC, useEffect, useMemo, useState} from "react";
import {useLocation, Link} from "react-router-dom";
import classnames from "classnames";
import {MenuItem, MENUS} from "../../../constants";
import {useSelector} from "react-redux";
import {getAccount} from "../../../redux/selectors";

interface IFullLayoutSidebarProps {
  sidebarCollapsed: boolean;
  onToggleSidebar(): void;
}

const Sidebar: FC<IFullLayoutSidebarProps> = ({
  sidebarCollapsed,
}) => {
  const location = useLocation();
  const account = useSelector(getAccount);
  const [activeMenu, setActiveMenu] = useState<MenuItem>();

  const userName = useMemo(() => {
    const names = account.user.name.split(' ');
    if (names.length > 1) {
      return names.slice(0, 2).map((name) => name[0]).join('');
    }
    return account.user.name.slice(0, 2);
  }, [account]);

  useEffect(() => {
    const menu = MENUS.find((item) => location.pathname.startsWith(item.prefix));
    setActiveMenu(menu);
  }, [location]);

  return (
    <div className={classnames(
      'sidebar h-screen bg-primary overflow-auto transition-all px-2 py-6',
      sidebarCollapsed ? 'w-16' : 'w-52',
    )}>
      <div className={classnames(
        'flex-center bg-info-lighter text-4xl text-danger uppercase rounded-full mx-auto transition-all',
        sidebarCollapsed ? 'w-0 h-0 opacity-0' : 'w-24 h-24'
      )}>
        {userName}
      </div>

      <div className={classnames('transition-all', sidebarCollapsed ? 'mt-30' : 'mt-8')}>
        {MENUS.map((menu, i) => {
          if (menu.roles && !menu.roles.includes(account.user.role)) {
            return null;
          }

          return (
            <Link key={i} to={menu.link}>
              <div className={classnames(
                'flex-center rounded cursor-pointer transition-all px-4 py-2 mt-2',
                menu === activeMenu ? 'bg-white bg-opacity-60 text-primary' : 'hover:bg-white hover:bg-opacity-60 text-white',
              )}>
                <i className={classnames('w-5 text-center', menu.icon)}/>
                <span className={classnames(
                  'w-0 truncate transition-all',
                  !sidebarCollapsed && 'flex-grow ml-3'
                )}>
                {menu.text}
              </span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;

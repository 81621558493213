import moment from "moment";
import {IPaginatedResult} from "../interfaces";

export const formatDateTime = (datetime: string | Date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!datetime) {
    return '';
  }
  return moment(datetime).format(format);
};

export const capitalize = (str: string) => {
  if (!str) {
    return '';
  }
  return `${str[0].toUpperCase()}${str.substr(1)}`;
};

export const formatPaginatedResult = (Model, data): IPaginatedResult<typeof Model> => {
  return {
    ...data,
    data: data.data.map((item) => new Model(item)),
  };
};

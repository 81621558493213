import React, {FC, MouseEventHandler, ReactElement, useMemo} from "react";
import classnames from "classnames";

type ButtonVariant = 'standard' | 'outline';
type ButtonColor = 'default' | 'primary' | 'secondary' | 'danger' | 'white';
type ButtonShape = 'standard' | 'icon';

export interface IButtonProps {
  variant?: ButtonVariant;
  color?: ButtonColor;
  className?: string;
  shape?: ButtonShape;
  leftIcon?: ReactElement;
  leftIconClass?: string;
  rightIcon?: ReactElement;
  rightIconClass?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children?: any;
}

export const Button: FC<IButtonProps> = ({
  children,
  className = '',
  variant = 'standard',
  color = 'primary',
  shape = 'standard',
  leftIcon,
  leftIconClass = '',
  rightIcon,
  rightIconClass = '',
  type = 'button',
  disabled,
  ...props
}) => {
  const buttonTheme = useMemo(() => {
    const classes: string[] = [];
    if (color !== 'default') {
      classes.push('border');
    }
    switch (color) {
      case 'primary':
        if (variant === 'standard') {
          classes.push('bg-primary text-white border-primary');
        } else {
          classes.push('text-primary border-primary hover:bg-primary hover:text-white');
        }
        break;

      case 'danger':
        if (variant === 'standard') {
          classes.push('bg-danger-light text-white border-danger-light');
        } else {
          classes.push('text-danger-light border-danger-light hover:bg-danger-light hover:text-white');
        }
        break;
    }

    return classes;
  }, [variant, color]);

  return (
    <button
      className={classnames(
        'flex-center text-sm font-semibold outline-none rounded px-4 py-1.5 transition-all',
        buttonTheme,
        className,
        disabled ? 'opacity-60' : 'hover:shadow-md',
      )}
      type={type}
      disabled={disabled}
      {...props}
    >
      {
        !!leftIcon && (
          <div className={classnames(
            'flex-shrink-0 flex items-center',
            { 'mr-2': shape !== 'icon' },
            leftIconClass
          )}>{leftIcon}</div>
        )
      }
      { children }
      {
        !!rightIcon && (
          <div className={classnames(
            'flex-shrink-0 flex items-center',
            { 'ml-2': shape !== 'icon' },
            rightIconClass
          )}>{rightIcon}</div>
        )
      }
    </button>
  );
};

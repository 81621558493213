import moment from "moment";
import {Role} from "../enums";
import {TeamModel} from "./team.model";

export class UserModel {
  id: number;
  name: string;
  role: Role;
  team?: TeamModel;
  encKey: string;
  decKey: string;
  interval: number;
  machineCount: number;
  appVersion: string;
  lastLoggedIn: string;
  keyUpdatedAt: string;
  createdAt: string;

  constructor(init: any = {}) {
    const data = {
      id: null,
      name: '',
      role: Role.USER,
      encKey: '',
      decKey: '',
      interval: 10,
      machineCount: 0,
      appVersion: '',
      lastLoggedIn: null,
      keyUpdatedAt: null,
      ...init,
    };

    this.id = data.id;
    this.name = data.name;
    this.role = data.role;
    this.encKey = data.encKey;
    this.decKey = data.decKey;
    this.interval = data.interval;
    this.machineCount = data.machineCount;
    this.appVersion = data.appVersion;
    this.lastLoggedIn = data.lastLoggedIn;
    this.keyUpdatedAt = data.keyUpdatedAt;
    this.createdAt = data.createdAt;

    if (data.team) {
      this.team = new TeamModel(data.team);
    }
  }

  shouldUpdateKey() {
    if (!this.keyUpdatedAt) {
      return true;
    }
    return moment().diff(this.keyUpdatedAt, 'days') > 30;
  }
}

import {HttpService} from "./http.service";
import {IPaginatedResult, IUserActivity} from "../utils/interfaces";
import {trimObject} from "../utils/helpers";

export class ActivitiesService {

  static async search(filter, showSpinner = true): Promise<IPaginatedResult<IUserActivity>> {
    return HttpService.get('/admin/data/activities', trimObject(filter), {}, showSpinner);
  }
}

import React from "react";
import {Route, Switch} from "react-router-dom";
import {ROUTES} from "../../constants";
import Users from "./index";

const UsersRouting = () => (
  <Switch>
    <Route exact path={ROUTES.USERS.INDEX} component={Users} />
  </Switch>
);

export default UsersRouting;

import * as CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";

export const checksum = (str: string, format = CryptoJS.enc.Hex) => {
  return CryptoJS.SHA256(str).toString(format);
};

export const encryptAES = (plain: string, key: string, format = CryptoJS.format.OpenSSL) => {
  return CryptoJS.AES.encrypt(plain, key).toString(format);
};

export const decryptAES = (cipher: string, key: string, format = CryptoJS.enc.Utf8) => {
  return CryptoJS.AES.decrypt(cipher, key).toString(format);
};

export const encryptRSA = (plain: string, publicKey: string) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(plain);
};

export const decryptRSA = (cipher: string, privateKey: string) => {
  const encrypt = new JSEncrypt();
  encrypt.setPrivateKey(privateKey);
  return encrypt.decrypt(cipher);
};

import {AUTH_ACTION} from "../action-types";
import {AccountModel, SettingsModel} from "../../utils/types";

export const setToken = (token: string) => ({
  type: AUTH_ACTION.SET_TOKEN,
  token,
});

export const setAccount = (account: AccountModel) => ({
  type: AUTH_ACTION.SET_ACCOUNT,
  account,
});

export const setSettings = (settings: SettingsModel) => ({
  type: AUTH_ACTION.SET_SETTINGS,
  settings,
});

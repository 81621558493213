import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Input} from "../../../components";
import {Form, FormControl, useForm, Validators} from "../../../components/ReactiveForm";
import {getAccount} from "../../../redux/selectors";
import {TeamService} from "../../../services";
import {TeamModel, UserModel} from "../../../utils/types";

export interface ITeamManagerFormInitParams {
  getFormData: () => Promise<any>;
}

export interface ITeamManagerFormProps {
  manager?: UserModel;
  onInit?: (params: ITeamManagerFormInitParams) => void;
  onClose?: () => void;
}

export type ITeamManagerForm = {
  name: FormControl;
  teamId: FormControl;
  password: FormControl;
}

const TeamManagerForm: FC<ITeamManagerFormProps> = ({
  manager,
  onInit = () => {},
}) => {
  const account = useSelector(getAccount);
  const [teams, setTeams] = useState<TeamModel[]>([]);

  const [form] = useForm<ITeamManagerForm>({
    name: new FormControl('', [Validators.required()]),
    teamId: new FormControl('', [Validators.required()]),
    password: new FormControl('', manager ? [] : [Validators.required()]),
  });

  const teamOptions = useMemo(() => (
    teams.map((team) => team.id)
  ), [teams]);

  useEffect(() => {
    if (manager) {
      form.patch({
        name: manager.name,
        teamId: manager.team?.id || null,
      });
    }
  }, [manager]);

  const getFormData = useCallback(async () => {
    if (!form.validate()) {
      return null;
    }
    const data = form.getFormData();
    if (!data.password) {
      delete data.password;
    }
    return data;
  }, [form, account]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  useEffect(() => {
    TeamService.search({ sort: 'name' }, false).then((res) => {
      setTeams(res.data);
    });
  }, []);

  const getTeamLabel = useCallback((id?: number) => {
    if (!id) {
      return '';
    }
    const team = teams.find((team) => team.id === id);
    return team?.name ?? `Team-${id}`;
  }, [teams]);

  return (
    <Form className="flex flex-col gap-4" formGroup={form}>
      <Input
        control={form.controls.name}
        size="sm"
        fullWidth
        label="Name"
        placeholder="name"
      />

      <Input
        control={form.controls.teamId}
        type="autocomplete"
        size="sm"
        fullWidth
        label="Team"
        placeholder="Select team"
        options={teamOptions}
        getOptionLabel={getTeamLabel}
      />

      <Input
        control={form.controls.password}
        type="password"
        size="sm"
        fullWidth
        label="Password"
        placeholder="Password"
      />
    </Form>
  );
};

export default TeamManagerForm;

import React, {useCallback, useMemo, useState} from "react";
import {debounce} from "lodash";
import {IconButton, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import {Button, DataTable, IDataTableColumn, IDataTableRef, Menu, TextField} from "../../components";
import TeamCreateModal from "./TeamCreateModal";
import TeamEditModal from "./TeamEditModal";
import {MessageBoxService, ToastService, TeamService} from "../../services";
import {TeamModel} from "../../utils/types";

const Teams = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    search: '',
  });
  const [tableRef, setTableRef] = useState<IDataTableRef>();
  const [totalCount, setTotalCount] = useState<number>();
  const [selectedTeams, setSelectedTeams] = useState<number[]>([]);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [editingTeam, setEditingTeam] = useState<TeamModel>();

  const loadTeams = useCallback((options, callback) => {
    TeamService.search({
      skip: options.page * options.perPage,
      limit: options.perPage,
      sort: options.sort,
      search: filter.search,
    }, false).then((res) => {
      setTotalCount(res.totalCount);
      callback({
        count: res.totalCount,
        data: res.data,
      });
    }).catch(() => {
      setTotalCount(0);
      callback({
        count: 0,
        data: [],
      });
    });
  }, [filter]);

  const refreshTable = useCallback(() => {
    if (tableRef) {
      tableRef.refresh(true);
    }
  }, [tableRef]);

  const debouncedSetFilter = useCallback(debounce((filter) => setFilter(filter), 500), []);

  const onSearchChange = (search) => {
    setSearch(search);
    debouncedSetFilter({
      ...filter,
      search,
      page: 0,
    });
  };

  const onEditTeam = useCallback((team: TeamModel) => {
    setEditingTeam(team);
  }, []);

  const onDeleteTeam = useCallback((team: TeamModel) => {
    MessageBoxService.confirm({
      title: 'Confirm',
      message: <>Are you sure you want to delete this team?<br/>All screenshots for this team will be deleted permanently.</>
    }).then((result) => {
      if (result) {
        TeamService.delete(team.id).then(() => {
          ToastService.success('Team deleted successfully');
          refreshTable();
        }).catch((err) => {
          ToastService.showHttpError(err, 'Deleting team failed');
        });
      }
    });
  }, [refreshTable]);

  const columns = useMemo<IDataTableColumn[]>(() => [
    {
      title: '#',
      render(_, i) {
        return <>{i + 1}</>;
      },
    },
    {
      title: 'Name',
      field: 'name',
      sortable: true,
      render(row: TeamModel) {
        return (
          <div
            className="hover:underline cursor-pointer"
            onClick={() => setEditingTeam(row)}
          >
            {row.name}
          </div>
        );
      },
    },
    { title: 'Members', field: 'userCount' },
    {
      title: 'Actions',
      render(row: TeamModel) {
        return (
          <div className="flex items-center">
            <Tooltip title="Edit Team" arrow placement="top">
              <IconButton className="shrink-0" size="small" onClick={() => onEditTeam(row)}>
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Team" arrow placement="top">
              <IconButton className="shrink-0" size="small" onClick={() => onDeleteTeam(row)}>
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ], [onEditTeam, onDeleteTeam]);

  const handleSelectedTeamsAction = async (action) => {
    switch (action) {
      case 'delete': {
        const result = await MessageBoxService.confirm({
          title: 'Confirm',
          message: <>Are you sure you want to delete selected teams?</>
        });
        if (result) {
          TeamService.deleteMany(selectedTeams).then(() => {
            ToastService.success(`${selectedTeams.length} teams deleted successfully`);
            setSelectedTeams([]);
            refreshTable();
          }).catch((err) => {
            ToastService.showHttpError(err, 'Deleting teams failed');
          });
        }
        return;
      }
    }
  };

  const onCloseCreateTeamModal = (result) => {
    if (result) {
      refreshTable();
    }

    setShowCreateTeamModal(false);
  };

  const onCloseEditTeamModal = (result) => {
    if (result) {
      refreshTable();
    }

    setEditingTeam(undefined);
  };

  return (
    <>
      <div className="px-8 py-10">
        <h1 className="text-primary text-xl font-bold">Teams ({totalCount})</h1>

        <div className="bg-white rounded shadow py-6 mt-4">
          <div className="flex items-center flex-wrap px-6">
            <TextField
              size="sm"
              fullWidth
              containerClass="w-80 mr-auto"
              value={search}
              icon={<i className="fa fa-search text-primary" />}
              placeholder="Search"
              onChange={onSearchChange}
            />

            {selectedTeams.length > 0 && (
              <div className="flex items-center text-sm ml-8">
                <span>Selected <b>{selectedTeams.length}</b> teams</span>
                <Button
                  className="text-xs ml-4"
                  color="danger"
                  variant="outline"
                  onClick={() => setSelectedTeams([])}
                >
                  Deselect All
                </Button>
                <Menu
                  className="ml-4"
                  text="Actions"
                  items={[
                    { text: 'Delete Teams', value: 'delete', icon: <i className="fa fa-trash mr-2" /> },
                  ]}
                  menuClass="rounded mt-1"
                  menuItemClass="!text-primary !text-xs"
                  buttonProps={{ className: 'text-xs' }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onSelectItem={(option) => handleSelectedTeamsAction(option.value)}
                />
              </div>
            )}

            <Button
              className="text-xs ml-8"
              leftIcon={<i className="fa fa-plus" />}
              onClick={() => setShowCreateTeamModal(true)}
            >
              Add Team
            </Button>
          </div>

          <DataTable
            wrapperClass="mt-4"
            columns={columns}
            serverSide
            datasource={loadTeams}
            checkboxSelection
            headerCheckboxSelection
            selectedRows={selectedTeams}
            defaultSort={{ field: 'name', direction: 'asc' }}
            pagination="auto"
            rowsPerPage={10}
            size="xs"
            headerCellClass="!px-10"
            cellClass="!px-10"
            onInit={setTableRef}
            onSelectionChange={setSelectedTeams}
          />
        </div>
      </div>

      {showCreateTeamModal && (
        <TeamCreateModal onClose={onCloseCreateTeamModal} />
      )}

      {editingTeam && (
        <TeamEditModal team={editingTeam} onClose={onCloseEditTeamModal} />
      )}
    </>
  );
};

export default Teams;

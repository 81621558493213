import React from "react";
import {Route, Switch} from "react-router-dom";
import {ROUTES} from "../../constants";
import TeamManagers from "./index";

const TeamManagersRouting = () => (
  <Switch>
    <Route exact path={ROUTES.TEAM_MANAGERS.INDEX} component={TeamManagers} />
  </Switch>
);

export default TeamManagersRouting;

import React from "react";
import {useSelector} from "react-redux";
import {getConfirmModalOptions} from "../../redux/selectors";
import {ConfirmationModal} from "../ConfirmationModal";

export const MessageBoxContainer = () => {
  const confirmModalOptions = useSelector(getConfirmModalOptions);

  if (confirmModalOptions) {
    return (
      <ConfirmationModal {...confirmModalOptions} />
    );
  }

  return null;
}

import {UserModel} from "./user.model";

export class AccountModel {
  user: UserModel;
  lastLoggedIn: string;
  masterKey: string;
  kData: string;

  constructor(init: Partial<AccountModel> = {}) {
    const data = {
      lastLoggedIn: null,
      masterKey: null,
      kData: '',
      ...init,
    };

    this.user = data.user;
    this.lastLoggedIn = data.lastLoggedIn;
    this.masterKey = data.masterKey;
    this.kData = data.kData;
  }
}

import React, {memo} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import {createTheme, ThemeProvider} from "@mui/material";
import {ROUTES} from "./constants";
import {MessageBoxContainer, ResetKeyReminder, SpinnerContainer, ToastContainer} from "./components";
import {Preloader} from "./providers";
import FullLayout from "./layouts/FullLayout";
import {getAccount} from "./redux/selectors";
import AuthRouting from "./pages/Auth/routing";
import ScreenshotsRouting from "./pages/Screenshots/routing";
import ActivitiesRouting from "./pages/Activities/routing";
import UsersRouting from "./pages/Users/routing";
import TeamsRouting from "./pages/Teams/routing";
import TeamManagersRouting from "./pages/TeamManagers/routing";
import SettingsRouting from "./pages/Settings/routing";
import {Role} from "./utils/enums";

const theme = createTheme({
  typography: {
    fontFamily: 'sans-serif',
  },
  palette: {
    text: {
      primary: '#192440',
    },
  },
});

const App = memo(() => {
  const account = useSelector(getAccount);

  return (
    <ThemeProvider theme={theme}>
      {!account ? (
        <AuthRouting />
      ) : (
        <Preloader>
          <Switch>
            <FullLayout>
              <Switch>
                <Route key={ROUTES.USERS.PREFIX} path={ROUTES.USERS.PREFIX} component={UsersRouting} />
                <Route key={ROUTES.SCREENSHOTS.PREFIX} path={ROUTES.SCREENSHOTS.PREFIX} component={ScreenshotsRouting} />
                <Route key={ROUTES.ACTIVITIES.PREFIX} path={ROUTES.ACTIVITIES.PREFIX} component={ActivitiesRouting} />
                {account.user.role === Role.ADMIN && (
                  <>
                    <Route key={ROUTES.TEAMS.PREFIX} path={ROUTES.TEAMS.PREFIX} component={TeamsRouting} />
                    <Route key={ROUTES.TEAM_MANAGERS.PREFIX} path={ROUTES.TEAM_MANAGERS.PREFIX} component={TeamManagersRouting} />
                    <Route key={ROUTES.SETTINGS.PREFIX} path={ROUTES.SETTINGS.PREFIX} component={SettingsRouting} />
                  </>
                )}
                <Redirect to={ROUTES.USERS.INDEX} />
              </Switch>
            </FullLayout>
          </Switch>

          <ResetKeyReminder />
        </Preloader>
      )}

      <ToastContainer />
      <MessageBoxContainer />
      <SpinnerContainer />
    </ThemeProvider>
  );
});

export default App;

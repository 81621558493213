export class SettingsModel {
  interval: number;
  resetPasswordReminderDays: number;
  lastKeyUpdated: string;
  lastClearedAt: string;
  lastClearedFrom: string;
  lastClearedTo: string;
  createdAt: string;
  updatedAt: string;

  constructor(init: Partial<SettingsModel> = {}) {
    const data = {
      interval: 10,
      resetPasswordReminderDays: 30,
      lastKeyUpdated: null,
      lastClearedAt: null,
      lastClearedFrom: null,
      lastClearedTo: null,
      createdAt: null,
      updatedAt: null,
      ...init,
    };

    this.interval = data.interval;
    this.resetPasswordReminderDays = data.resetPasswordReminderDays;
    this.lastKeyUpdated = data.lastKeyUpdated;
    this.lastClearedAt = data.lastClearedAt;
    this.lastClearedFrom = data.lastClearedFrom;
    this.lastClearedTo = data.lastClearedTo;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}

import React, {useCallback, useMemo, useState} from "react";
import {debounce} from "lodash";
import {IconButton, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import {Button, DataTable, IDataTableColumn, IDataTableRef, TeamSelect, TextField} from "../../components";
import UserCreateModal from "./TeamManagerCreateModal";
import UserEditModal from "./TeamManagerEditModal";
import {MessageBoxService, ToastService, UserService} from "../../services";
import {Role} from "../../utils/enums";
import {formatDateTime} from "../../utils/helpers";
import {UserModel} from "../../utils/types";

const TeamManagers = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    search: '',
    teamId: null,
  });
  const [tableRef, setTableRef] = useState<IDataTableRef>();
  const [totalCount, setTotalCount] = useState<number>();
  const [selectedManagers, setSelectedManagers] = useState<number[]>([]);
  const [showCreateManagerModal, setShowCreateManagerModal] = useState(false);
  const [editingManager, setEditingManager] = useState<UserModel>();

  const loadTeamManagers = useCallback((options, callback) => {
    UserService.search({
      skip: options.page * options.perPage,
      limit: options.perPage,
      sort: options.sort,
      search: filter.search,
      teamId: filter.teamId,
      role: Role.TEAM_MANAGER,
    }, false).then((res) => {
      setTotalCount(res.totalCount);
      callback({
        count: res.totalCount,
        data: res.data,
      });
    }).catch(() => {
      setTotalCount(0);
      callback({
        count: 0,
        data: [],
      });
    });
  }, [filter]);

  const refreshTable = useCallback(() => {
    if (tableRef) {
      tableRef.refresh(true);
    }
  }, [tableRef]);

  const debouncedSetFilter = useCallback(debounce((filter) => setFilter(filter), 500), []);

  const onSearchChange = (search) => {
    setSearch(search);
    debouncedSetFilter({
      ...filter,
      search,
      page: 0,
    });
  };

  const onFilterChange = (field, value) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const onEditManager = useCallback((user: UserModel) => {
    setEditingManager(user);
  }, []);

  const onDeleteManager = useCallback((user: UserModel) => {
    MessageBoxService.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to delete this team manager?',
    }).then((result) => {
      if (result) {
        UserService.delete(user.id).then(() => {
          ToastService.success('Team manager deleted successfully');
          refreshTable();
        }).catch((err) => {
          ToastService.showHttpError(err, 'Deleting team manager failed');
        });
      }
    });
  }, [refreshTable]);

  const columns = useMemo<IDataTableColumn[]>(() => [
    {
      title: '#',
      render(_, i) {
        return <>{i + 1}</>;
      },
    },
    {
      title: 'Name',
      field: 'name',
      sortable: true,
      render(row: UserModel) {
        return (
          <div
            className="hover:underline cursor-pointer"
            onClick={() => setEditingManager(row)}
          >
            {row.name}
          </div>
        );
      },
    },
    { title: 'Team', field: 'team.name' },
    {
      title: 'Created at',
      field: 'createdAt',
      sortable: true,
      render(row: UserModel) {
        return formatDateTime(row.createdAt, 'DD MMM YYYY, h:mm A');
      },
    },
    {
      title: 'Actions',
      render(row: UserModel) {
        return (
          <div className="flex items-center">
            <Tooltip title="Edit Manager" arrow placement="top">
              <IconButton className="shrink-0" size="small" onClick={() => onEditManager(row)}>
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Manager" arrow placement="top">
              <IconButton className="shrink-0" size="small" onClick={() => onDeleteManager(row)}>
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ], [onEditManager, onDeleteManager]);

  const onDeleteSelectedManagers = async () => {
    const result = await MessageBoxService.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to delete selected team managers?,'
    });
    if (result) {
      UserService.deleteMany(selectedManagers).then(() => {
        ToastService.success(`${selectedManagers.length} team managers deleted successfully`);
        setSelectedManagers([]);
        refreshTable();
      }).catch((err) => {
        ToastService.showHttpError(err, 'Deleting team managers failed');
      });
    }
  };

  const onCloseCreateManagerModal = (result) => {
    if (result) {
      refreshTable();
    }

    setShowCreateManagerModal(false);
  };

  const onCloseEditManagerModal = (result) => {
    if (result) {
      refreshTable();
    }

    setEditingManager(undefined);
  };

  return (
    <>
      <div className="px-8 py-10">
        <h1 className="text-primary text-xl font-bold">Team Managers ({totalCount})</h1>

        <div className="bg-white rounded shadow py-6 mt-4">
          <div className="flex items-center flex-wrap gap-x-8 gap-y-4 px-6">
            <div className="flex items-center gap-4 mr-auto">
              <TextField
                size="sm"
                fullWidth
                containerClass="w-100"
                value={search}
                icon={<i className="fa fa-search text-primary" />}
                placeholder="Search"
                onChange={onSearchChange}
              />
              <TeamSelect value={filter.teamId} onChange={(value) => onFilterChange('teamId', value)} />
            </div>

            {selectedManagers.length > 0 && (
              <div className="flex items-center text-sm gap-4">
                <span>Selected <b>{selectedManagers.length}</b> managers</span>
                <Button
                  className="text-xs"
                  color="danger"
                  variant="outline"
                  onClick={() => setSelectedManagers([])}
                >
                  Deselect All
                </Button>
                <Button
                  className="text-xs"
                  color="danger"
                  variant="outline"
                  onClick={onDeleteSelectedManagers}
                >
                  Delete Selection
                </Button>
              </div>
            )}

            <Button
              className="text-xs"
              leftIcon={<i className="fa fa-plus" />}
              onClick={() => setShowCreateManagerModal(true)}
            >
              Add Team Manager
            </Button>
          </div>

          <DataTable
            wrapperClass="mt-4"
            columns={columns}
            serverSide
            datasource={loadTeamManagers}
            checkboxSelection
            headerCheckboxSelection
            selectedRows={selectedManagers}
            defaultSort={{ field: 'name', direction: 'asc' }}
            pagination="auto"
            rowsPerPage={10}
            size="xs"
            headerCellClass="!px-10"
            cellClass="!px-10"
            onInit={setTableRef}
            onSelectionChange={setSelectedManagers}
          />
        </div>
      </div>

      {showCreateManagerModal && (
        <UserCreateModal onClose={onCloseCreateManagerModal} />
      )}

      {editingManager && (
        <UserEditModal manager={editingManager} onClose={onCloseEditManagerModal} />
      )}
    </>
  );
};

export default TeamManagers;

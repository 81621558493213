import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Tooltip} from "@mui/material";
import {Button, Input} from "../../../components";
import {Form, FormControl, useForm, Validators} from "../../../components/ReactiveForm";
import {getAccount, getSettings} from "../../../redux/selectors";
import {KeyService, TeamService} from "../../../services";
import {Role} from "../../../utils/enums";
import {copyToClipboard, encryptAES, getMasterKey, saveAs} from "../../../utils/helpers";
import {TeamModel, UserModel} from "../../../utils/types";

export interface IUserFormInitParams {
  getFormData: () => Promise<any>;
}

export interface IUserFormProps {
  user?: UserModel;
  onInit?: (params: IUserFormInitParams) => void;
}

export type IUserForm = {
  name: FormControl;
  encKey: FormControl;
  decKey: FormControl;
  interval: FormControl;
  teamId: FormControl;
}

const UserForm: FC<IUserFormProps> = ({
  user,
  onInit = () => {},
}) => {
  const account = useSelector(getAccount);
  const settings = useSelector(getSettings);
  const isSuperAdmin = account.user.role === Role.ADMIN;

  const [form] = useForm<IUserForm>({
    name: new FormControl('', [Validators.required()]),
    encKey: new FormControl('', [Validators.required()]),
    decKey: new FormControl(''),
    interval: new FormControl(settings.interval, [Validators.required(), Validators.min(1)]),
    teamId: new FormControl(''),
  });
  const [teams, setTeams] = useState<TeamModel[]>([]);
  const [keyChanged, setKeyChanged] = useState(false);

  const teamOptions = useMemo(() => (
    teams.map((team) => team.id)
  ), [teams]);

  useEffect(() => {
    if (user) {
      form.patch({
        name: user.name,
        encKey: user.encKey,
        interval: user.interval,
        teamId: user.team?.id,
      });
    } else {
      onGenerateKeys();
    }
  }, [user]);

  const getFormData = useCallback(async () => {
    if (!form.validate()) {
      return null;
    }
    const formData = form.getFormData();
    const masterKey = await getMasterKey();
    if (!masterKey) {
      return null;
    }

    const encryptedKey = encryptAES(formData.decKey, masterKey);

    return {
      ...formData,
      decKey: encryptedKey,
      interval: Number(formData.interval),
      teamId: Number(formData.teamId) || null,
      keyChanged,
    };
  }, [form, account, keyChanged]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  useEffect(() => {
    if (!isSuperAdmin) {
      return;
    }

    TeamService.search({ sort: 'name' }, false).then((res) => {
      setTeams(res.data);
    });
  }, [isSuperAdmin]);

  const getTeamLabel = useCallback((id?: number) => {
    if (!id) {
      return '';
    }
    const team = teams.find((team) => team.id === id);
    return team?.name ?? `Team-${id}`;
  }, [teams]);

  const onGenerateKeys = () => {
    KeyService.generateRsaKeys().then((data) => {
      form.patch({
        encKey: data.publicKey,
        decKey: data.privateKey,
      });
      setKeyChanged(true);
    });
  };

  const onCopyKeys = () => {
    const { encKey } = form.getFormData();
    copyToClipboard(encKey);
  };

  const onDownloadKeys = () => {
    const { name, encKey } = form.getFormData();
    const blob = new Blob([encKey]);
    saveAs(blob, `${name || 'unnamed user'}-key.pem`);
  };

  return (
    <Form formGroup={form}>
      <Input
        control={form.controls.name}
        size="sm"
        fullWidth
        label="User"
        placeholder="User name"
      />

      {isSuperAdmin && (
        <Input
          control={form.controls.teamId}
          type="autocomplete"
          size="sm"
          fullWidth
          label="Team"
          placeholder="Select team"
          options={teamOptions}
          getOptionLabel={getTeamLabel}
          containerClass="mt-4"
        />
      )}

      <div className="flex items-center mt-4">
        <Tooltip title="Copy to clipboard" arrow>
          <i className="far fa-copy text-gray hover:text-primary text-xl cursor-pointer ml-auto" onClick={onCopyKeys} />
        </Tooltip>
        <Tooltip title="Download key" arrow>
          <i className="fa fa-download text-gray hover:text-primary text-xl cursor-pointer ml-3" onClick={onDownloadKeys} />
        </Tooltip>
        <Button
          type="button"
          className="ml-3"
          leftIcon={<i className="fa fa-refresh" />}
          onClick={onGenerateKeys}
        >
          Generate Key
        </Button>
      </div>

      <Input
        type="textarea"
        control={form.controls.encKey}
        size="sm"
        fullWidth
        minRows={5}
        readonly
        label="Encryption Key"
        inputClass="!text-xs"
      />

      <Input
        type="number"
        control={form.controls.interval}
        size="sm"
        fullWidth
        min={1}
        label="Screenshot Interval"
        icon={<span>mins</span>}
        iconPosition="right"
        containerClass="mt-4"
      />
    </Form>
  );
};

export default UserForm;

import React, {FC} from "react";
import {FormControlLabel, Switch as MaterialSwitch} from "@mui/material";
import {IAbstractInputControlProps} from "../ReactiveForm";

export type SwitchLabelPosition = 'top' | 'bottom' | 'start' | 'end';

export interface ISwitchProps {
  value?: boolean;
  labelPosition?: SwitchLabelPosition;
  labelClass?: string;
}

export const Switch: FC<ISwitchProps & IAbstractInputControlProps> = ({
  className= '',
  name = '',
  value,
  label = '',
  labelPosition = 'end',
  labelClass = '',
  disabled = false,
  onChange = () => {},
}) => {
  return (
    <FormControlLabel
      classes={{ root: className, label: labelClass }}
      name={name}
      value={value || ''}
      control={(
        <MaterialSwitch
          color="primary"
          checked={value || false}
          onChange={(_, value) => onChange(value)}
        />
      )}
      label={label}
      labelPlacement={labelPosition}
      disabled={disabled}
    />
  );
};

import React, {FC, useState} from "react";
import {Button, Modal} from "../../../components";
import TeamManagerForm, {ITeamManagerFormInitParams} from "../TeamManagerForm";
import {ToastService, UserService} from "../../../services";
import {UserModel} from "../../../utils/types";
import {Role} from "../../../utils/enums";

interface ITeamManagerEditModalProps {
  manager: UserModel;
  onClose: (result?: any) => void;
}

const TeamManagerEditModal: FC<ITeamManagerEditModalProps> = ({
  manager,
  onClose,
}) => {
  const [teamManagerForm, setTeamManagerForm] = useState<ITeamManagerFormInitParams>({
    getFormData: () => null,
  });

  const onSubmit = async () => {
    const data = await teamManagerForm.getFormData();
    if (!data) {
      return;
    }

    try {
      const updatedTeamManager = await UserService.update(manager.id, {
        ...data,
        role: Role.TEAM_MANAGER,
      });
      ToastService.success('Team manager updated successfully');
      onClose(updatedTeamManager);
    } catch (e) {
      ToastService.showHttpError(e, 'Updating team manager failed');
    }
  };

  return (
    <Modal
      title="Edit Team Manager"
      footerComponent={(
        <>
          <Button
            type="button"
            className="px-12 ml-auto"
            onClick={onSubmit}
          >
            Update
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <TeamManagerForm manager={manager} onInit={setTeamManagerForm} />
    </Modal>
  );
};

export default TeamManagerEditModal;

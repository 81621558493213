import React, {FC, useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import {CONFIG} from "../../../constants";
import {ScreenshotModel} from "../../../utils/types";
import {formatDateTime} from "../../../utils/helpers";
import ScreenshotView from "../ScreenshotView";

export interface IPreviewModalProps {
  files?: ScreenshotModel[];
  file: ScreenshotModel;
  decKey: string;
  onClose(): void;
}

const PreviewModal: FC<IPreviewModalProps> = ({
  files = [],
  file,
  decKey,
  onClose,
}) => {
  const [index, setIndex] = useState(files.indexOf(file));
  const currentFile = files[index] || file;

  const onPrev = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const onNext = () => {
    if (index < files.length - 1) {
      setIndex(index + 1);
    }
  };

  return (
    <div className="fixed w-screen h-screen top-0 left-0 z-max flex-center flex-col">
      <div
        className="absolute w-full h-full top-0 left-0 bg-black bg-opacity-80 backdrop-blur-[1px] -z-1"
        onClick={onClose}
      />

      <ScreenshotView
        className="!max-w-[90%] !max-h-[90%]"
        screenshot={currentFile}
        decKey={decKey}
      />

      <div className="text-white text-xs truncate mt-4">
        {formatDateTime(currentFile.timestamp, 'h:mm A, D MMM YYYY')}
      </div>

      {files.length > 0 && (
        <i
          className={classnames(
            'absolute left-4 fa fa-angle-left text-white fa-2x',
            index === 0 ? 'opacity-60 cursor-default' : 'cursor-pointer'
          )}
          onClick={onPrev}
        />
      )}

      {files.length > 0 && (
        <i
          className={classnames(
            'absolute right-4 fa fa-angle-right text-white fa-2x',
            index >= files.length - 1 ? 'opacity-60 cursor-default' : 'cursor-pointer'
          )}
          onClick={onNext}
        />
      )}

      <i
        className="absolute top-4 right-4 far fa-times-circle text-white fa-2x cursor-pointer"
        onClick={onClose}
      />
    </div>
  );
};

export default PreviewModal;

import React, {FC, ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import {get} from "lodash";
import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow, TableSortLabel
} from "@mui/material";
import "./style.scss";

export interface IDataTableColumn {
  title?: string;
  field?: string;
  sortable?: boolean;
  minWidth?: number;
  maxWidth?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  headerClass?: string;
  cellClass?: string;
  render?: (row: any, index: number, data: any[]) => string | ReactElement | null;
}

export interface IDataTableRef {
  refresh: (forceUpdate?: boolean) => void;
}

export interface IDataSourceOptions {
  page?: number;
  perPage?: number;
  sort?: string;
  forceUpdate?: boolean;
}

export interface IDataSource {
  count: number;
  data: any[];
}

export interface ISortModel {
  field: string;
  direction?: 'asc' | 'desc';
}

export type TableSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type ClassFn = (row: any, id: number) => string;

export type DataSourceCallbackFn = (result: IDataSource) => void;

export type DataSourceFn = (options: IDataSourceOptions, callback: DataSourceCallbackFn) => void;

export interface IDataTableProps {
  className?: string;
  columns: IDataTableColumn[];
  serverSide?: boolean;
  data?: any[];
  datasource?: DataSourceFn;
  totalCount?: number;
  size?: TableSize;
  stickyHeader?: boolean;
  title?: string | ReactElement;
  titleClass?: string;
  wrapperClass?: string;
  tableContainerClass?: string;
  headerCellClass?: string;
  rowClass?: string | ClassFn;
  cellClass?: string | ClassFn;
  paginationClass?: string;
  stripped?: boolean;
  checkboxSelection?: boolean;
  headerCheckboxSelection?: boolean;
  selectedRows?: any[];
  defaultSort?: ISortModel;
  page?: number;
  pagination?: boolean | 'auto';
  selfPagination?: boolean;
  rowsPerPage?: number;
  extraOptions?: ReactElement;     // extra table options in the top-right corner
  onInit?: (ref: IDataTableRef) => void;
  onSelectionChange?: (rows: any[]) => void;
  onPaginationChange?: (page: number, perPage: number) => void;
}

export const DataTable: FC<IDataTableProps> = ({
  className = '',
  columns,
  serverSide = false,
  data,
  datasource,
  totalCount,
  size = 'md',
  stickyHeader = false,
  title,
  titleClass,
  wrapperClass = '',
  tableContainerClass = '',
  headerCellClass = '',
  rowClass = '',
  cellClass = '',
  paginationClass = '',
  stripped = false,
  checkboxSelection = false,
  headerCheckboxSelection = false,
  selectedRows,
  defaultSort,
  page: pageNum,
  pagination = false,
  selfPagination = false,
  rowsPerPage = 50,
  extraOptions,
  onInit,
  onSelectionChange = () => {},
  onPaginationChange,
}) => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [sortModel, setSortModel] = useState<ISortModel>(defaultSort);

  useEffect(() => {
    if (pageNum !== undefined) {
      setPage(pageNum);
    }
  }, [pageNum]);

  useEffect(() => {
    if (!serverSide) {
      setTotalDataCount(totalCount || data.length);
    }
  }, [serverSide, data, totalCount]);

  const loadDataSource = useCallback((forceUpdate = false) => {
    if (!serverSide || !datasource) {
      return;
    }

    setLoading(true);
    let sort = undefined;
    if (sortModel) {
      sort = `${sortModel.direction === 'desc' ? '-' : ''}${sortModel.field}`;
    }
    datasource({
      page,
      perPage: rowsPerPage,
      sort,
      forceUpdate,
    }, (result) => {
      if (result) {
        setTotalDataCount(result.count);
        setPageData(result.data);
      }
      setLoading(false);
    });
  }, [serverSide, datasource, page, rowsPerPage, sortModel]);

  useEffect(() => {
    if (serverSide) {
      loadDataSource();
      return;
    }

    if (!pagination || selfPagination) {
      setPageData(data);
    } else {
      setPageData(data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }
  }, [serverSide, pagination, selfPagination, loadDataSource, data, page, rowsPerPage]);

  useEffect(() => {
    if (onInit) {
      onInit({
        refresh: loadDataSource,
      });
    }
  }, [onInit, loadDataSource]);

  const showPagination = useMemo(() => (
    pagination === true || (pagination === 'auto' && totalDataCount > rowsPerPage)
  ), [pagination, totalDataCount, rowsPerPage]);

  const onSelectRow = (row, checked) => {
    let rowIds;
    if (checked) {
      rowIds = [...selectedRowIds, row];
    } else {
      rowIds = selectedRowIds.filter((item) => item !== row);
    }
    setSelectedRowIds(rowIds);
    onSelectionChange(rowIds);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    if (onPaginationChange) {
      onPaginationChange(page, rowsPerPage);
    }
  };

  const onSortField = (field: string) => {
    if (sortModel?.field === field) {
      if (sortModel.direction === 'desc') {
        setSortModel(undefined);
      } else {
        setSortModel({
          field,
          direction: sortModel.direction === 'asc' ? 'desc' : 'asc',
        });
      }
    } else {
      setSortModel({
        field,
        direction: 'asc',
      });
    }
  }

  useEffect(() => {
    if (selectedRows) {
      setSelectedRowIds(selectedRows);
    }
  }, [selectedRows]);

  const selectedAll = pageData.length > 0 && pageData.every((row) => selectedRowIds.includes(row.id));
  const selectedSome = !selectedAll && pageData.some((row) => selectedRowIds.includes(row.id));

  const onToggleSelectAll = () => {
    const pageRowIds = pageData.map((row) => row.id);
    let rowIds = selectedRowIds.filter((id) => !pageRowIds.includes(id));
    if (!selectedAll) {
      rowIds = rowIds.concat(pageRowIds);
    }
    setSelectedRowIds(rowIds);
    onSelectionChange(rowIds);
  };

  return (
    <div className={classnames(
      `data-table w-full group relative flex flex-col table-${size}`,
      { 'table-stripped': stripped },
      wrapperClass,
    )}>
      <TableContainer className={classnames(
        'w-full relative',
        tableContainerClass,
        { '!min-h-55 !overflow-hidden': loading },
      )}>
        {title && (
          <div className={titleClass}>{title}</div>
        )}
        <Table className={className} stickyHeader={stickyHeader}>
          <TableHead>
            <TableRow>
              {checkboxSelection && (
                <TableCell padding="checkbox">
                  {headerCheckboxSelection && (
                    <Checkbox
                      className="checkbox-blue-light"
                      checked={selectedAll}
                      indeterminate={selectedSome}
                      onChange={onToggleSelectAll}
                    />
                  )}
                </TableCell>
              )}
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  className={classnames(headerCellClass, column.headerClass)}
                  align={column.align}
                  style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={sortModel?.field === column.field}
                      direction={sortModel?.field === column.field ? (sortModel.direction || 'asc') : undefined}
                      onClick={() => onSortField(column.field)}
                    >
                      {column.title || ''}
                    </TableSortLabel>
                  ) : (
                    column.title || ''
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData.map((row, rowId) => {
              const trClass = typeof rowClass === 'function' ? rowClass(row, rowId) : rowClass;
              const rowCellClass = typeof cellClass === 'function' ? cellClass(row, rowId) : cellClass;
              return (
                <TableRow key={rowId} tabIndex={-1} className={trClass}>
                  {checkboxSelection && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        className="checkbox-blue-light"
                        checked={selectedRowIds.includes(row.id)}
                        onChange={(_, checked) => onSelectRow(row.id, checked)}
                      />
                    </TableCell>
                  )}
                  {columns.map((column, colId) => (
                    <TableCell key={colId} align={column.align} className={classnames(rowCellClass, column.cellClass)}>
                      {column.render
                        ? column.render(row, page * rowsPerPage + rowId, data)
                        : column.field ? get(row, column.field) : null}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {loading && (
          <div className="absolute top-0 left-0 w-full h-full min-h-55 flex-center bg-white bg-opacity-80 pt-12">
            <CircularProgress
              sx={{
                color: '#0B1C34',
              }}
              size={40}
              thickness={4}
            />
          </div>
        )}
        {!loading && !pageData?.length && (
          <div className="text-center text-gray p-10">No Records</div>
        )}
      </TableContainer>

      {extraOptions && (
        <div className="absolute hidden group-hover:block top-1.5 right-4 z-10">
          {extraOptions}
        </div>
      )}

      {showPagination && (
        <TablePagination
          component="div"
          className={paginationClass}
          count={totalDataCount}
          page={page}
          rowsPerPage={rowsPerPage}
          showFirstButton
          showLastButton
          rowsPerPageOptions={[rowsPerPage]}
          onPageChange={(e, newPage) => onPageChange(newPage)}
        />
      )}
    </div>
  );
};

import React, {FC, useState} from "react";
import {Button, Modal} from "../../../components";
import TeamForm, {ITeamFormInitParams} from "../TeamForm";
import {ToastService, TeamService} from "../../../services";

interface ITeamCreateModalProps {
  onClose: (result?: any) => void;
}

const TeamCreateModal: FC<ITeamCreateModalProps> = ({
  onClose,
}) => {
  const [teamForm, setTeamForm] = useState<ITeamFormInitParams>({
    getFormData: () => null,
  });

  const onSubmit = async () => {
    const data = await teamForm.getFormData();
    if (!data) {
      return;
    }

    try {
      const team = await TeamService.create({
        name: data.name,
      });
      ToastService.success('Team created successfully');
      onClose(team);
    } catch (e) {
      ToastService.showHttpError(e, 'Creating team failed');
    }
  };

  return (
    <Modal
      title="Create a Team"
      footerComponent={(
        <>
          <Button
            type="button"
            className="px-12 ml-auto"
            onClick={onSubmit}
          >
            Create
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <TeamForm onInit={setTeamForm} />
    </Modal>
  );
};

export default TeamCreateModal;

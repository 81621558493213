import React, {FC, useState} from "react";
import {Button, Modal} from "../../../components";
import TeamManagerForm, {ITeamManagerFormInitParams} from "../TeamManagerForm";
import {ToastService, UserService} from "../../../services";
import {Role} from "../../../utils/enums";
import {UserModel} from "../../../utils/types";

interface ITeamManagerCreateModalProps {
  onClose: (result?: UserModel) => void;
}

const TeamManagerCreateModal: FC<ITeamManagerCreateModalProps> = ({
  onClose,
}) => {
  const [teamManagerForm, setTeamManagerForm] = useState<ITeamManagerFormInitParams>({
    getFormData: () => null,
  });

  const onSubmit = async () => {
    const data = await teamManagerForm.getFormData();
    if (!data) {
      return;
    }

    try {
      const teamManager = await UserService.create({
        ...data,
        role: Role.TEAM_MANAGER,
      });
      ToastService.success('Team manager created successfully');
      onClose(teamManager);
    } catch (e) {
      ToastService.showHttpError(e, 'Creating team manager failed');
    }
  };

  return (
    <Modal
      title="Create Team Manager"
      footerComponent={(
        <>
          <Button
            type="button"
            className="px-12 ml-auto"
            onClick={onSubmit}
          >
            Create
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <TeamManagerForm onInit={setTeamManagerForm} />
    </Modal>
  );
};

export default TeamManagerCreateModal;

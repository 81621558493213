import {HttpService} from "./http.service";
import {SettingsModel} from "../utils/types";

export class SettingsService {
  static get(showSpinner = true) {
    return HttpService.get('/admin/settings', {}, {}, showSpinner)
      .then((data) => new SettingsModel(data));
  }

  static resetMasterKey(data, showSpinner = true) {
    return HttpService.put('/admin/settings/master-key', data, {}, showSpinner);
  }

  static resetPassword(oldPassword, newPassword, showSpinner = true) {
    return HttpService.put('/admin/settings/password', { oldPassword, newPassword }, {}, showSpinner);
  }

  static updateInterval(interval: number, showSpinner = true) {
    return HttpService.put('/admin/settings/interval', { interval }, {}, showSpinner);
  }

  static updateResetPasswordReminder(reminder: number, showSpinner = true) {
    return HttpService.put('/admin/settings/reminder', { reminder }, {}, showSpinner);
  }
}

import React, {FC, ReactEventHandler, useEffect, useState} from "react";
import classnames from "classnames";
import {Popover} from "@mui/material";
import {DateRangePicker as ReactDateRagePicker} from "react-date-range";
import {IAbstractInputControlProps, InputWrapper} from "../ReactiveForm";
import {formatDateTime} from "../../utils/helpers";

export interface IDateRagePickerProps {
  value: Date[];
  placeholder?: string;
}

export const DateRagePicker: FC<IDateRagePickerProps & IAbstractInputControlProps> = ({
  name = '',
  value,
  placeholder = '',
  onChange = () => {},
  children,
  ...wrapperProps
}) => {
  const [selectionRange, setSelectionRange] = useState({
    key: name,
    startDate: value[0],
    endDate: value[1],
  });

  useEffect(() => {
    setSelectionRange({
      key: name,
      startDate: value[0],
      endDate: value[1],
    });
  }, [value]);

  const startLabel = formatDateTime(value[0], 'MM.DD.yyyy');
  const endLabel = formatDateTime(value[1], 'MM.DD.yyyy');
  const label = startLabel === endLabel ? startLabel : `${startLabel} - ${endLabel}`;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

  const onOpenDropdown: ReactEventHandler = (e) => {
    setAnchorEl(e.currentTarget as HTMLElement);
  };

  const onCloseDropdown = () => {
    setAnchorEl(null);
  };

  const onSelectRange = (e) => {
    const { startDate, endDate } = e[name];
    setSelectionRange((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));

    if (startDate !== endDate) {
      onChange([startDate, endDate]);
      onCloseDropdown();
    }
  };

  return (
    <InputWrapper {...wrapperProps}>
      {({ className }) => (
        <>
          <div
            className={classnames(
              'w-full flex items-center text-sm px-4 py-2',
              className,
            )}
            onClick={onOpenDropdown}
          >
            <span className="text-primary">{label}</span>
            <i className="far fa-calendar-alt text-gray ml-auto" />
          </div>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onCloseDropdown}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <ReactDateRagePicker
              ranges={[selectionRange]}
              monthDisplayFormat="MMMM yyyy"
              weekdayDisplayFormat="EEEEE"
              showMonthAndYearPickers={false}
              showDateDisplay={false}
              onChange={onSelectRange}
              updateRange={(e) => console.log(e)}
            />
          </Popover>
        </>
      )}
    </InputWrapper>
  );
};

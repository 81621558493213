import React, {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Snackbar, Stack} from "@mui/material";
import {closeToast} from "../../redux/actions";
import {getToastOptions} from "../../redux/selectors";

export type ToastSeverity = 'success' | 'info' | 'warning' | 'error';
export type ToastXPosition = 'left' | 'center' | 'right';
export type ToastYPosition = 'top' | 'bottom';

export interface IToastOptions {
  message: string | ReactElement;
  severity: ToastSeverity;
  autoHideDuration?: number;
  xPosition?: ToastXPosition;
  yPosition?: ToastYPosition;
}

export const ToastContainer = () => {
  const dispatch = useDispatch();
  const toastOptions = useSelector(getToastOptions);
  const [lastSeverity, setLastSeverity] = useState<ToastSeverity>();

  useEffect(() => {
    if (toastOptions) {
      setLastSeverity(toastOptions.severity);
      const timer = setTimeout(() => {
        dispatch(closeToast());
      }, toastOptions.autoHideDuration || 3500);
      return () => clearTimeout(timer);
    }
  }, [toastOptions]);

  const onClose = () => {
    dispatch(closeToast());
  };

  return (
    <Stack spacing={2}>
      <Snackbar
        open={Boolean(toastOptions)}
        anchorOrigin={{ horizontal: toastOptions?.xPosition || 'center', vertical: toastOptions?.yPosition || 'bottom' }}
      >
        <Alert
          variant="filled"
          severity={toastOptions?.severity || lastSeverity}
          onClose={onClose}
        >
          {toastOptions?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {Autocomplete} from "../Autocomplete";
import {ToastService, UserService} from "../../services";
import {Role} from "../../utils/enums";
import {UserModel} from "../../utils/types";

export interface IUserSelectProps {
  className?: string;
  value?: number[];
  onChange(ids: number[]): void;
  onLoadUsers?: (users: UserModel[]) => void;
}

export const UserSelect: FC<IUserSelectProps> = ({
  className,
  value,
  onChange,
  onLoadUsers,
}) => {
  const [users, setUsers] = useState<UserModel[]>([]);

  const options = useMemo(() => (
    users.map((user) => user.id)
  ), [users]);

  useEffect(() => {
    UserService.search({ role: Role.USER, sort: 'name' }, false).then((res) => {
      setUsers(res.data);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading users failed');
    });
  }, []);

  useEffect(() => {
    if (onLoadUsers) {
      onLoadUsers(users);
    }
  }, [users, onLoadUsers]);

  const getOptionLabel = useCallback((id: number) => {
    const user = users.find((user) => user.id === id);
    return user?.name ?? `User-${id}`;
  }, [users]);

  return (
    <Autocomplete
      size="sm"
      name="user"
      label="User"
      containerClass={className}
      fullWidth
      multiple
      disableCloseOnSelect
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      placeholder={`All (${users.length})`}
      onChange={onChange}
    />
  );
};

import {HttpService} from "./http.service";
import {formatPaginatedResult} from "../utils/helpers";
import {TeamModel} from "../utils/types";

export class TeamService {

  static async search(filter: any = {}, showSpinner = true) {
    return HttpService.get('/admin/team', filter, {}, showSpinner)
      .then((data) => formatPaginatedResult(TeamModel, data));
  }

  static async find(id: number, showSpinner = true) {
    return HttpService.get(`/admin/team/${id}`, {}, {}, showSpinner)
      .then((data) => new TeamModel(data));
  }

  static async create(data: Partial<TeamModel>, showSpinner = true) {
    return HttpService.post('/admin/team', data, {}, showSpinner)
      .then((data) => new TeamModel(data));
  }

  static async update(id: number, data: Partial<TeamModel>, showSpinner = true) {
    return HttpService.patch(`/admin/team/${id}`, data, {}, showSpinner)
      .then((data) => new TeamModel(data));
  }

  static async delete(id: number, showSpinner = true) {
    return HttpService.delete(`/admin/team/${id}`, {}, {}, showSpinner);
  }

  static async deleteMany(ids: number[], showSpinner = true) {
    return HttpService.delete(`/admin/team`, {ids}, {}, showSpinner);
  }
}

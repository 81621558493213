import {HttpService} from "./http.service";
import {formatPaginatedResult, trimObject} from "../utils/helpers";
import {ScreenshotModel} from "../utils/types";
import {DeleteScreenshotsDto, DeleteScreenshotsResponseDto} from "../utils/dto";

export class ScreenshotService {

  static async search(filter, showSpinner = true) {
    return HttpService.get('/admin/data', filter, {}, showSpinner)
      .then((data) => formatPaginatedResult(ScreenshotModel, data));
  }

  static async getAvailableMachines(filter, showSpinner = true) {
    return HttpService.get('/admin/data/available-machines', trimObject(filter), {}, showSpinner);
  }

  static async searchHistories(filter, showSpinner = true) {
    return HttpService.get('/admin/data/histories', filter, {}, showSpinner)
      .then((data) => formatPaginatedResult(ScreenshotModel, data));
  }

  static async find(id: number, showSpinner = true) {
    return HttpService.get(`/admin/data/${id}`, {}, {}, showSpinner)
      .then((data) => new ScreenshotModel(data));
  }

  static async updateAll(data, showSpinner = true): Promise<void> {
    return HttpService.put('/admin/data', data, {}, showSpinner);
  }

  static async deleteAll(filter: DeleteScreenshotsDto, showSpinner = true): Promise<DeleteScreenshotsResponseDto> {
    return HttpService.delete('/admin/data', filter, {}, showSpinner);
  }
}

import React, {useCallback, useEffect, useState} from "react";
import {debounce} from "lodash";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import {BounceLoader} from "react-spinners";
import {TablePagination} from "@mui/material";
import ModeSwitch, {MONITOR_MODE} from "../ModeSwitch";
import ScreenshotCard from "../ScreenshotCard";
import PreviewModal from "../PreviewModal";
import {Button, TeamSelect, TextField} from "../../../components";
import {ROUTES} from "../../../constants";
import {getAccount} from "../../../redux/selectors";
import {ScreenshotService} from "../../../services";
import {fromNow} from "../../../utils/helpers";
import {ScreenshotModel} from "../../../utils/types";
import {Role} from "../../../utils/enums";

const LiveScreenshots = () => {
  const history = useHistory();
  const account = useSelector(getAccount);
  const isSuperAdmin = account.user.role === Role.ADMIN;

  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    search: '',
    teamId: null,
    page: 0,
    perPage: 20,
  });
  const [loading, setLoading] = useState(false);
  const [screenshots, setScreenshots] = useState<ScreenshotModel[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [preview, setPreview] = useState<ScreenshotModel>();
  const [timestamp, setTimestamp] = useState(new Date());
  const [lastLoadTime, setLastLoadAt] = useState(new Date());

  const loadScreenshots = useCallback((showLoader = true) => {
    if (showLoader) {
      setLoading(true);
    }
    ScreenshotService.search({
      skip: filter.page * filter.perPage,
      limit: filter.perPage,
      username: filter.search,
      teamId: filter.teamId,
    }, false).then((res) => {
      setScreenshots(res.data);
      setTotalCount(res.totalCount);
      setPreview((preview) => {
        if (!preview) {
          return undefined;
        }
        return res.data.find((item) => item.user?.id === preview.user?.id);
      });
    }).catch(() => {
      setScreenshots([]);
    }).finally(() => {
      setLoading(false);
      setLastLoadAt(new Date());
    });
  }, [filter]);

  useEffect(() => {
    loadScreenshots();
    const timer = setInterval(() => {
      loadScreenshots(false);
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [loadScreenshots]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimestamp(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const debouncedSetFilter = useCallback(debounce((filter) => setFilter(filter), 500), []);

  const onSearchChange = (search: string) => {
    setSearch(search);
    debouncedSetFilter({
      ...filter,
      search,
      page: 0,
    });
  };

  const onFilterChange = (field: string, value: any) => {
    setFilter((filter) => ({
      ...filter,
      [field]: value,
    }));
  };

  const onSwitchMode = () => {
    history.push(ROUTES.SCREENSHOTS.HISTORY);
  };

  return (
    <>
      <div className="px-8 py-10">
        <div className="flex items-center">
          <h1 className="text-primary text-xl font-bold">Screen Monitoring</h1>
          <div className="ml-2">({totalCount} Members)</div>
          {isSuperAdmin && (
            <TeamSelect className="w-80 ml-8" value={filter.teamId} onChange={(value) => onFilterChange('teamId', value)}/>
          )}
          <Button
            className="ml-auto"
            color="primary"
            variant="outline"
            onClick={() => loadScreenshots()}
          >
            <i className="fa fa-refresh mr-2"/> Refresh
          </Button>
          {isSuperAdmin && (
            <ModeSwitch className="ml-8" value={MONITOR_MODE.LIVE} onToggle={onSwitchMode} />
          )}
        </div>

        <div className="bg-white rounded shadow p-6 mt-4">
          <div className="flex items-center">
            <TextField
              size="sm"
              fullWidth
              containerClass="sm:w-80"
              value={search}
              icon={<i className="fa fa-search text-primary"/>}
              placeholder="Search"
              onChange={onSearchChange}
            />
            {lastLoadTime && (
              <span className="text-sm ml-auto">
                Last API call: {fromNow(lastLoadTime, '')}
              </span>
            )}
          </div>

          {loading ? (
            <div className="flex-center h-80">
              <BounceLoader color="#0B1C34" size={60}/>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
              {screenshots.map((item, i) => (
                <ScreenshotCard
                  key={i}
                  className="h-[40vw] sm:h-[20vw] lg:h-[16vw]"
                  mode={MONITOR_MODE.LIVE}
                  screenshot={item}
                  decKey={item.user?.decKey}
                  onPreview={() => setPreview(item)}
                />
              ))}
            </div>
          )}

          {!loading && !screenshots.length && (
            <div className="flex-center text-center h-80 px-4 py-8">No users found.</div>
          )}

          {filter.perPage < totalCount && (
            <TablePagination
              component="div"
              className="mt-4 -mb-4"
              count={totalCount}
              page={filter.page}
              rowsPerPage={filter.perPage}
              showFirstButton
              showLastButton
              rowsPerPageOptions={[filter.perPage]}
              onPageChange={(_, page) => onFilterChange('page', page)}
            />
          )}
        </div>
      </div>

      {preview && (
        <PreviewModal
          file={preview}
          decKey={preview.user?.decKey}
          onClose={() => setPreview(undefined)}
        />
      )}
    </>
  );
};

export default LiveScreenshots;

import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setSettings} from "../redux/actions";
import {SettingsService, ToastService} from "../services";

export const Preloader = ({
  children,
}) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    SettingsService.get().then((settings) => {
      dispatch(setSettings(settings));
    }).catch((err) => {
      ToastService.showHttpError(err, 'Load settings failed');
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  if (loading)
    return null;

  return <>{children}</>;
};

import React, {ReactElement} from "react";
import axios from "axios";
import store from "../redux/store";
import {showToast} from "../redux/actions";
import {IToastOptions} from "../components";

export class ToastService {

  static success(message: string | ReactElement, options?: Partial<IToastOptions>) {
    ToastService.show({
      ...options,
      message,
      severity: 'success',
    });
  }

  static info(message: string | ReactElement, options?: Partial<IToastOptions>) {
    ToastService.show({
      ...options,
      message,
      severity: 'info',
    });
  }
  static warning(message: string | ReactElement, options?: Partial<IToastOptions>) {
    ToastService.show({
      ...options,
      message,
      severity: 'warning',
    });
  }
  static error(message: string | ReactElement, options?: Partial<IToastOptions>) {
    ToastService.show({
      ...options,
      message,
      severity: 'error',
    });
  }

  static show(options: IToastOptions) {
    store.dispatch(showToast(options));
  }

  static showHttpError(err, defaultMessage: string | ReactElement) {
    if (!axios.isAxiosError(err)) {
      ToastService.error(err?.message || defaultMessage);
      return;
    }

    const response = err?.response;
    const data = response?.data;

    if (!response) {
      ToastService.error('Network Error!');
      return;
    }

    if (!data) {
      ToastService.error(`${response.status} error - ${response.statusText}`);
      return;
    }

    if (data.message) {
      ToastService.error(data.message);
      return;
    }

    ToastService.error(defaultMessage);
  }
}
